import React from "react";
import Modal from "../../../../components/Modal";
import { CrossIcon } from "../../../../assets/svgIcon";
import CreditDetails from "./CreditDetails";

const CreditDetailModal = ({ setCreditModal, isCreditModalOpen }) => {
  return (
    <Modal maxWidth="xl" open={isCreditModalOpen}>
        <div>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div className="fw600">Credit Details </div>
        <CrossIcon
          onClick={() => {
            setCreditModal(false);
          }}
          className="cross-icon"
        />
      </div>
      <CreditDetails />
      </div>
    </Modal>
  );
};

export default CreditDetailModal;
