import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import "./home.scss";
import Table from "../../components/Table";
import { fetchUserList } from "../../adapters/users";
import useColumns from "./useColumns";
import  Select  from './Select';
import SearchBar from "./SearchBar";
import Filters from './Filters';
import {FilterIcon} from '../../assets/svgIcon'

const Home = () => {
  const [isFilterModalOpen, setFilterModal] = useState(false)
  const [userListData, setUserListData] = useState([]);
  const [isUserListLoading, setUserListLoading] = useState(true)
  const {columns} = useColumns()
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const history = useHistory();


  useEffect(() => {
    // TODO: lift this state to redux store
    fetchUserList(currentPage, pageSize)
      .then((res) => {
        const userList = res.payload.data;
        setTotalCount(res.payload.count)
        const mappedUserList = userList.map((user) => {
          const {rank, phone_number, first_name, email, credit_score, onboarding_state, uuid, id} = user;
          return {
            rank: rank,
            name: first_name,
            uuid: uuid,
            id:id,
            clientID: "#2333oi2", // TODO: ask backend team for clientID
            phoneNo: phone_number,
            emailId: email,
            creditScore: credit_score,
            activationDate: "12/05/21", // TODO: ask backend team for activationDate
            internalScore: "90/21", // TODO: ask backend team for internalScore
            status: onboarding_state,
          };
        });
        setUserListData(mappedUserList);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setTimeout(() => {
          setUserListLoading(false)
        }, 500)
      })
  }, [currentPage, pageSize]);
  

  const onRowClick = (values) => {
    history.push(`/users/${values.original.uuid}`);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setUserListLoading(true)
  }

  const onRowsPerPageChange = (e) => {
    setPageSize(e.target.value)
    setUserListLoading(true)
  }

  return (
    <Layout>
      <p className="header-text"> Users </p>
      <div className="header-bar"> 
      <Filters  
      isFilterModalOpen={isFilterModalOpen}
      setFilterModal={setFilterModal}
      />
      <Select onRowsPerPageChange={onRowsPerPageChange} rowCount={pageSize} /> 
      <button onClick={() => setFilterModal(true)} className="filter-btn d-flex-c-c"> <FilterIcon className="mr6" /> Filters</button>
      </div>
      <div className="react-table">
        <Table
          serverSidePagination={true}
          isLoading={isUserListLoading}
          columns={columns}
          data={userListData}
          onRowClicked={onRowClick}
          onPageChange={onPageChange}
          totalCount={totalCount}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
    </Layout>
  );
};

export default Home;
