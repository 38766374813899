import Auth from "../pages/Auth";
import NotFound from "../pages/NotFound";
import Users from "../pages/Users";
import { authUrls, rootUrls } from "./urls";
import Overview from "../pages/InternalScore";
import Transactions from "../pages/Transactions";
import Billing from "../pages/Billing";
import Settings from "../pages/Settings";
import Merchant from "../pages/Merchant";
import Home from "../pages/Home";
import UserDetails from "../pages/Users/UserDetails";

export const publicRoutesList = [
  {
    path: authUrls.loginPath,
    component: Auth,
  },
  {
   path: rootUrls.homePath,
    component: Home,
  },
  {
    path: rootUrls.notFoundPath,
    component: NotFound,
  },
];

export const privateRoutesList = [
  {
    path: rootUrls.usersPath,
    component: Users,
    role: ["executive", "admin"],
  },
  {
    path: rootUrls.userDetailsPath,
    component: UserDetails,
    role: ["executive", "admin"],
  },
  {
    path: rootUrls.internalScorePath,
    component: Overview,
    role: ["executive", "admin"]
  },
  {
    path: rootUrls.transactionPath,
    component: Transactions,
    role: ["executive", "admin"]
  },
  {
    path: rootUrls.merchantPath,
    component: Merchant,
    role: ["executive", "admin"]
  },
  {
    path: rootUrls.billingPath,
    component: Billing,
    role: ["executive", "admin"],
  },
  {
    path: rootUrls.settingsPath,
    component: Settings,
    role: ["executive", "admin"]
  }
];


export const menuListPaths = [
  {
    name: "Users",
    path: rootUrls.usersPath,
    role: ["executive", "admin"],
  },
  {
    name: "Internal Score",
    path: rootUrls.internalScorePath,
    role: ["executive", "admin"]
  },
  {
    name: "Transactions",
    path: rootUrls.transactionPath,
    role: ["executive", "admin"]
  },
  {
    name: "Merchant",
    path: rootUrls.merchantPath,
    role: ["executive", "admin"]
  },
  {
    name: "Billing",
    path: rootUrls.billingPath,
    role: ["executive", "admin"],
  },
  {
    name: "Settings",
    path: rootUrls.settingsPath,
    role: ["executive", "admin"]
  }
];