import React, { useState } from "react";
import "./repayments.scss";
import {
  HorizontalIcon,
  SuccessDotIcon,
  RupeeIcon,
} from "../../../../assets/svgIcon";
import TransactionsList from './TransactionsList'

const Repayments = () => {
  const [isTransactionModalOpen, setTransactionModal  ] = useState(false)
   return (
    <section className="repayments-wrap p16">
      <TransactionsList setTransactionModal={setTransactionModal} isTransactionModalOpen={isTransactionModalOpen}   />
      <div className="header d-flex-c-s">
        <div className="f16 fw600">REPAYMENTS</div>
        <div className="f16 fw400">
          Total amount: <span className="fw600"> ₹60,000</span>{" "}
        </div>
      </div>

      <div className="body my20">
        <div className="first-installment mb24">
          <div className="d-flex-c-s">
            <div className="d-flex-c-f">
              <p className="f16 fw700 text-primary">1st Installment</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500">Status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700">PAID</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 mr8">Date of payment : </p>
              <p className="f14 fw700 mr16">15 July 2022</p>

              <div onClick={() => setTransactionModal(true)} className="view-transactions px16 d-flex-c-c mx-auto f12 fw400 text-center">
              View transactions
            </div>
            </div>
            <div>
              <div className="d-flex-c-f">
                <RupeeIcon className="mr8" />
                <p className="f12 fw500 mr8">Amount : </p>
                <p className="f14 fw700">₹20,000</p>
              </div>
            </div>
          </div>
        </div>

        <div className="second-installment mb24">
          <div className="d-flex-c-s">
            <div className="d-flex-c-f">
              <p className="f16 fw700 text-primary m0">2nd Installment</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 m0">Status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700 m0">OVERDUE</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 mr8 m0">Interest rate : </p>
              <p className="f14 fw700 m0">8%</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 mr8 m0">Late fees : </p>
              <p className="f14 fw700 m0">₹20,000</p>
            </div>
            <div>
              <div className="d-flex-c-f">
                <RupeeIcon className="mr8" />
                <p className="f12 fw500 mr8 m0">Amount : </p>
                <p className="f14 fw700 m0">₹20,000</p>
              </div>
            </div>
          </div>

          <div className="installment-details d-flex-c-s"> 
            <p className="fw600 f12">Due date :</p>
            <p className="fw500 f12">30 July 2022</p>
          </div>

          <div className="installment-details d-flex-c-s"> 
            <p className="fw600 f12">Paid date :</p>
            <p className="fw500 f12">_</p>
          </div>

        </div>

        <div className="third-installment">
          <div className="d-flex-c-s">
            <div className="d-flex-c-f">
              <p className="f16 fw700 text-primary m0">3rd Installment</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 m0">Status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700 m0">DUE</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 mr8 m0">Interest rate : </p>
              <p className="f14 fw700 m0">8%</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500 mr8 m0">Late fees : </p>
              <p className="f14 fw700 m0">_</p>
            </div>

            <div className="d-flex-c-f">
              <RupeeIcon className="mr8" />
              <p className="f12 fw500 mr8 m0">Amount : </p>
              <p className="f14 fw700 m0">₹20,000</p>
            </div>
          </div>

          <div className="installment-details d-flex-c-s"> 
            <p className="fw600 f12">Due date :</p>
            <p className="fw500 f12">30 July 2022</p>
          </div>

          <div className="installment-details d-flex-c-s"> 
            <p className="fw600 f12">Paid date :</p>
            <p className="fw500 f12">_</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Repayments;
