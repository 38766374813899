import React from 'react'
import Layout from '../../components/Layout'


const Transactions = () => {
  return (
    <Layout>Transactions</Layout>
  )
}

export default Transactions