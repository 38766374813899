import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import { publicRoutesList, privateRoutesList } from "./routesList";
import { isUserLoggedIn } from "../utils/user";
import {useSelector} from 'react-redux'


const CustomRoutes = () => {
  const {data: { type: userRole }} = useSelector((state) => state.auth);
  // TODO: handle the case when there is no accessibility to any route
  const firstAccessiblePrivateRoute = privateRoutesList.filter(route => route.role.includes(userRole))[0]?.path;
  return (
    <>
      {publicRoutesList.map((props, key) => (
        <Route exact render={props.render} {...props} key={key} />
      ))}
      {privateRoutesList.map((props, key) => {
        if (isUserLoggedIn() && props.role.includes(userRole)) {
          return <Route exact render={props.render} {...props} key={key} />;
        }
        return <Redirect to={firstAccessiblePrivateRoute} />;
      })}
    </>
  );
};

const Index = () => {
  return (
    <Router>
      <Switch>
        <CustomRoutes />
      </Switch>
    </Router>
  );
};

export default Index;
