import React from 'react';
import { Hidden, Drawer } from '@material-ui/core';
import cx from 'classnames'
import PropTypes from 'prop-types';
import { noop } from '../../utils';
import MenuList from './MenuList';

const MenuSidebar = ({ sidebarOpen, handleSidebarToggle }) => {
  const classes = {
    paper: 'menu-sidebar',
  };
  return (
    <nav className={cx("menu-sidebar-wrap")}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={sidebarOpen}
          onClose={handleSidebarToggle}
          classes={classes}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MenuList />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
         <MenuList />
      </Hidden>
    </nav>
  );
};

MenuSidebar.defaultProps = {
  sidebarOpen: false,
  handleSidebarToggle: noop,
};

MenuSidebar.propTypes = {
  /**
   * Is this Sidebar open ?
   */
  sidebarOpen: PropTypes.bool.isRequired,
  /**
   * How to handle toggle of sidebar
   */
  handleSidebarToggle: PropTypes.func.isRequired,
};

export default MenuSidebar;
