import React from 'react'

const index = () => {
  
  // TODO: replace the component with proper 404 page
  return (
    <div>Nothing Found here 404</div>
  )
}

export default index