import React from "react";

const AssertDetails = ({ className, title, information }) => {
  return (
    <div className={`${className} assert-details mt10`}>
      <p className="m0 f12 fc4E596F ">{title}</p>
      <p className="m0 f14 fw600 text-primary">{information}</p>
    </div>
  );
};

export default AssertDetails;
