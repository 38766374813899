import React from "react";
import { Avatar } from "@material-ui/core";

const getRandomColor = (name) => {
  if (!name) return "#E8E8EB";

  let hex = Math.floor(Math.random() * 0xffffff);
  let color = "#" + hex.toString(16);

  return color;
};

const useColumns = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => <span className="header-title pl24">Total Amount</span>,
        accessor: "rank",
        Cell: ({ cell: { value } }) => (
          <>
            <div className="tb-rank text-ellipsis tb-text">{`₹35551234`}</div>
          </>
        ),
      },
      {
        Header: () => <span className="header-title pl24">From</span>,
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <div className="tb-name">
            <Avatar
              style={{
                backgroundColor: getRandomColor(value),
              }}
            >
              {value?.split("")[0]}
            </Avatar>
            <p className="text-ellipsis">{`Devesh Kashyap`}</p>
          </div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Transactions Date</span>,
        accessor: "clientID",
        Cell: ({ cell: { value } }) => (
          <div className="tb-clientId text-ellipsis tb-text fw500">{`12/05/2021, 11:03 am`}</div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">PAYMENT METHOD</span>,
        accessor: "phoneNo",
        Cell: ({ cell: { value } }) => (
          <div className="tb-phoneNo text-ellipsis fw600">{`Pay Later`}</div>
        ),
        isOccurrence: true,
      },
    ],
    []
  );
  return { columns };
};

export default useColumns;
