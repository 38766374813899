import React from "react";
import LoginForm from "./LoginForm";
import Logo from "../../assets/svg/logo.svg";
import illustration from "../../assets/svg/illustration.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar">
        <Link to="/">
          <img className="navbar__brand" src={Logo} alt="yelow" />
        </Link>
      </nav>
    </div>
  );
};

const Login = () => {
  return (
    <>
      <div className="auth-container ">
        <Navbar />
        <div className="row auth-layout">
          <div className="flex-half pl-0 illustration-container">
            <img src={illustration} alt={``} />
          </div>
          <div className="flex-half auth-container">
            <div className="auth-form-wrap">
              <h2 className="section-text-1">Welcome to Yelow! 👋</h2>
              <h2 className="section-text-6 mt8 auth-layout-text">
                Please sign-in to your account and start the adventure
              </h2>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
