import React from "react";
import { withRouter } from "react-router-dom";
import NavSpan from "./NavSpan";
import { UsersNavIcon, SwitchIcon } from "../../assets/svgIcon";
import {menuListPaths} from '../../routes/routesList'




const MenuList = () => {
  const path = window.location.href;
  

  return (
    <div>
      <div className="menu-list-wrap">
        <p className="menu-header-text">
          Dashboard
          {/* Replace with Switch Component */}
          <SwitchIcon />
        </p>
        <ul className="p0">
          {
            menuListPaths.map((item, index) => {
              return (
                <li key={index}>
                 {/* TODO: handle nested routing case */}
                <NavSpan
                  path={path}
                  to={item.path}
                  isActive={false}
                >
                  {/* TODO: make icon dynamic */}
                  <UsersNavIcon />
                  <p> {item.name} </p>
                </NavSpan>
                </li>
              );
            })
          }
        </ul>
      </div>
    </div>
  );
};

export default withRouter(MenuList);
