import axios from 'axios';
import {getAuthToken, logoutUser} from '../utils/user'


export const http = (method, config) => {
  const { url, headers = {}, data = {} } = config;
  const paramsKey = method === 'get' ? 'params' : 'data';

  const requestHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  };

  const token = getAuthToken() 
  if (token) {
    requestHeaders.Authorization = token;
  }

  const axioOptions = {
    method,
    url,
    headers: requestHeaders,
    [paramsKey]: data,
  };


  return new Promise((resolve, reject) => {
    axios(axioOptions)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        const { response } = err;
        if (response) {
          if (response.status === 401) {
            logoutUser()
          } else {
            reject(response.data);
          }
        } else {
          reject(new Error('Something went wrong'));
        }
      });
  });
};

export const GET = (config) => http('get', config);

export const POST = (config) => http('post', config);

export const PUT = (config) => http('put', config);

export const PATCH = (config) => http('patch', config);

export const DELETE = (config) => http('delete', config);

