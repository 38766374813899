import React from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import FormGroup from './FormGroup';
import './form.scss'

const FormInput = (props) => {
  const {
    className,
    name,
    validate,
    labelText,
    required,
    size,
    onChange,
    helpText,
    defaultValue,
    ...restInputProps
  } = props;


  return (
    <Field name={name} validate={validate} defaultValue={defaultValue}>
      {({ input }) => {
        const handleChange = (e, value) => {
          input.onChange(e);
          onChange(e);
        };
        return (
          <FormGroup
            labelText={labelText}
            required={required}
            size={size}
            helpText={helpText}
            className={cx('input-wrap', className)}
          >
            <input autoComplete="off" {...input} {...restInputProps} onChange={handleChange} />
          </FormGroup>
        );
      }}
    </Field>
  );
};

FormInput.defaultProps = {
  className: '',
  name: '',
  validate: null,
  labelText: '',
  required: false,
  disabled: false,
  type: 'text',
  autoComplete: 'off',
  size: 'lg', // md
  helpText: '',
  onChange: () => {},
};

export default FormInput;