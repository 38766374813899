import React from 'react'
import Layout from '../../components/Layout'


const Settings = () => {
  return (
    <Layout>Settings</Layout>
  )
}

export default Settings