import React from "react";
import "./transactions.scss";
import Table from "../../../../../components/Table";
import useColumns from "./useColumns";

const data = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const Transactions = () => {
  const { columns } = useColumns();
  return (
    <div className="transactions-modal-wrap">
      <div className="react-transactions-table">
        <Table
          serverSidePagination={false}
          isLoading={false}
          columns={columns}
          data={data}
        />
      </div>
    </div>
  );
};

export default Transactions;
