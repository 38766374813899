import React, { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from 'react-router-dom';
import { getUserDetails } from '../../../adapters/users';
import CustomTabs from "../../../components/CustomTabs";
import Layout from "../../../components/Layout";
import "./userDetails.scss";
import UserDetails from "./UserDetails";
import {UserDetailsHeader} from './UserDetails'
import InternalScore from "./InternalScore";
import Documents from './Documents'
import CreditBureau from './CreditBureau'
import Repayments from './Repayments'
import Transactions from "./Transactions";

const Index = () => {
  const { id } = useParams()
  const [userDetailsData, setUserDetails] = React.useState({})

  useEffect(() => {
    getUserDetails(id)
    .then(res => {
      setUserDetails(res.payload.data)
    })
    .catch(err => {
      console.log('err', err)
    })
  }, [id])

  const TABS = [
    {
      id: "userDetails",
      title: "User Details",
      lazyRender: true,
      component: UserDetails,
      componentProps: {userDetails: userDetailsData}
    },
    {
      id: "internalScore",
      title: "Internal Score",
      lazyRender: true,
      component:InternalScore
    },
    {
      id: "documentation",
      title: "Documentation",
      lazyRender:true,
      component: Documents,
    },
    {
      id: "creditBureau",
      title: "Credit bureau",
      lazyRender:true,
      component: CreditBureau,
    },
    {
      id: "repayments",
      title: "Repayments",
      lazyRender:true,
      component: Repayments,
    },
    {
      id: 'transactions',
      title: "Transactions",
      lazyRender:true,
      component: Transactions,
    },
    {
      id: 'onlineOrders',
      title: "Online orders",
      lazyRender:true,
      component: () => <></>
    },
    {
      id: 'familyDetails',
      title: "familyDetails",
      lazyRender:true,
      component: () => <></>
    }
  ];

  return (
    <Layout>
      <UserDetailsHeader userDetails={userDetailsData} />
      <CustomTabs
       className="custom-tabs-buttons-ui"
       onChange={() => {}}
       defaultActiveIndex={0}
       tabs={TABS} 
       />
    </Layout>
  );
};

export default Index;
