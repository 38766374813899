import React, { useState, useEffect } from 'react';
import { Switch as MaterialSwitch, FormControlLabel } from '@material-ui/core';
import cx from 'classnames';
import { noop } from '../../utils';
import './switch.scss'

const Switch = (props) => {
  const { labelText, checked, className, onChange, disabled, lightOffTheme } = props;
  const [isChecked, setChecked] = useState(false);
  
  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const classes = cx('switch', className, {
    'switch-disabled': disabled,
    'MuiSwitch-light-off-theme': lightOffTheme,
  });

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };


  return (
    <FormControlLabel
      className="switch-label"
      control={
        <MaterialSwitch
          disableRipple
          disabled={disabled}
          className={classes}
          checked={isChecked}
          onChange={handleChange}
        />
      }
      label={labelText}
    />
  );
};

Switch.defaultProps = {
  checked: false,
  disabled: false,
  onChange: noop,
  labelText: '',
  className: '',
  lightOffTheme: false,
};

export default Switch;
