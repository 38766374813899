import { useEffect } from "react";
import { getAuthToken } from "../utils/user";
import { useHistory } from "react-router-dom";
import { privateRoutesList } from "../routes/routesList";
import { getUserRole } from "../utils/user";

export const useFirstAccessibleRoute = () => {
    const userRole = getUserRole()
    const history = useHistory();
    const firstAccessiblePrivateRoute = privateRoutesList.filter(route => route.role.includes(userRole))[0]?.path;
    
    useEffect(() => {
      const token = getAuthToken();
      if (token) {
        history.push(firstAccessiblePrivateRoute);
      } else {
        history.push('/login');
      }
    }, []);
}