import React, { useState } from "react";
import AssertDetails from "./AssertDetails";
import { TotalLoanIcon, SuccessDotIcon } from "../../../../assets/svgIcon";
import DpdModal from "./DpdModal";
import CreditDetailsModal from './CreditDetailsModal'

const DpdDetails = () => {
  const [isDpdModalOpen, setDpdModal] = useState(false)
  const [isCreditModalOpen, setCreditModal] = useState(false)
  return (
    <div className="dpd-details px16">
      <DpdModal isDpdModalOpen={isDpdModalOpen} setDpdModal={setDpdModal} />
      <CreditDetailsModal  setCreditModal={setCreditModal} isCreditModalOpen={isCreditModalOpen} />
      <div>
        <p className="fw600 f20 my0 pt16">Credit bureau</p>

        <div className="max-dpd mt24">
          <div>Maximum DPD</div>
          <div>2</div>
          <div onClick={() => setDpdModal(true)} className="ylw-secondary-btn">Details</div>
        </div>
        <AssertDetails
          title={`Total number of account`}
          information={`10`}
          className="grid-row-2-1 pt16"
        />
        <AssertDetails
          title={`Number of active account`}
          information={`8`}
          className="grid-row-2-1 pt8"
        />
      </div>

      <div className="mt64">
        <div className="ylw-indicator f12 fw600">
          YELOW INDICATOR <SuccessDotIcon className="mx12" />{" "}
          <span className="f12 fw400">Good to go </span>
        </div>
        <AssertDetails
          title={`Derogatory in CIBIL`}
          information={`YES`}
          className="grid-row-2-1 pt16"
        />
        <AssertDetails
          title={`Address inside of decided geography`}
          information={`YES`}
          className="grid-row-2-1 pt8"
        />
      </div>

      <div className="total-loan-amount mt12">
        <div className="d-flex-c-f">
          <TotalLoanIcon />
          <p className="f14 ml12">
            Total loan amount: <span className="fw700">₹60,000</span>
          </p>
        </div>

        <div className="d-flex-c-f">
          <TotalLoanIcon />
          <p className="f14 ml12">
            Total due amount: <span className="fw700">₹60,000</span>
          </p>
        </div>

        <div className="d-flex-c-f">
          <TotalLoanIcon />
          <p className="f14 ml12">
            Credit score: <span className="fw700">721</span>
          </p>
        </div>
        <button onClick={() => setCreditModal(true)} className="mt16 ylw-secondary-btn see-details-btn">
          See credit Details
        </button>
      </div>
    </div>
  );
};

export default DpdDetails;
