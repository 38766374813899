import React, { useState } from "react";
import { SaveBtnIcon } from "../../../assets/svgIcon";


const criteriaInitialState = [
  {
   criteria: 'Anybody below',
   name:'No loan',
   value: '80'
  },
  // TODO: fix seperate object for Anybody between criteria
  {
    criteria: 'Anybody between',
    name: 'Manual verification',
    value: '80',
  },
  {
    criteria: 'Anybody between',
    name:'Manual verification',
    value: '90',
  },
  {
    criteria : 'Anybody above',
    name:'Loan approved',
    value: '90'
  },
]

const LoanCriteria = () => {
  const [loanCriteriaFields, setLoanCriteriaFields] = useState(criteriaInitialState)

  const handleScoringFieldChange = (event, index) => {
    let data = [...loanCriteriaFields];
    data[index][event.target.name] = event.target.value;
    setLoanCriteriaFields(data);
  }

  const onCriteriSubmit = () => {
    console.log('loanCriteriaFields', loanCriteriaFields)
  }

  return (
      <>
       <p className="fw500 f12 fc4E596F mb24">Loan Criteria</p>
        <div className="edit-criteria-modal loan-criteria">

        <div className="edit-criteria-box mb10">
            <div className="text-primary d-flex-c-f f12"> {`Anybody below`}</div>
            <input name='value' onChange={event => handleScoringFieldChange(event, 0)} className="edit-input" value={loanCriteriaFields[0].value} />
            <div className="d-flex-c-f ml12 f12 fcA7A9BE"> Internal score</div>
            <div className="disabled-input-ui fw400 f12">{`No loan`}</div>
        </div>

        <div className="edit-criteria-box mb10">
            <div className="text-primary d-flex-c-f f12"> {`Anybody between`}</div>
            <div className="d-flex-c-s">
            <input name='value' onChange={event => handleScoringFieldChange(event, 1)} className="edit-input multi-input" value={loanCriteriaFields[1].value} />
            <span className="f10 fcA7A9BE">  to </span>
            <input name='value' onChange={event => handleScoringFieldChange(event, 2)} className="edit-input multi-input" value={loanCriteriaFields[2].value} />
            </div>
            <div className="d-flex-c-f ml12 f12 fcA7A9BE"> Internal score</div>
            <div className="disabled-input-ui fw400 f12">{`Manual verification`}</div>
        </div>

        <div className="edit-criteria-box mb10">
            <div className="text-primary d-flex-c-f f12"> {`Anybody above`}</div>
            <input name='value' onChange={event => handleScoringFieldChange(event, 3)} className="edit-input" value={loanCriteriaFields[3].value} />
            <div className="d-flex-c-f ml12 f12 fcA7A9BE"> Internal score</div>
            <div className="disabled-input-ui fw400 f12">{`Loan approved`}</div>
        </div>

        <div className="d-flex-c-c mt56">
          <button onClick={onCriteriSubmit} className="d-flex-c-c ylw-primary-btn px24 py10 fw500">
            <SaveBtnIcon className="mr8" /> Save
          </button>
        </div>
      </div>
      </>
  );
};

export default LoanCriteria;
