import React, { useState } from "react";
import { PreviewIcon } from "../../../assets/svgIcon";
import EditCriteriaNumber from "./EditCriteriaNumber";
import EditRangeCriteria from './EditRangeCriteria'
import OtpModal from './OtpModal'

const ScoringCriteria = () => {
  const [isEditCriteriaModalOpen, setCriteriaModal] = useState(false);
  const [isRangeCriteriaModalOpen, setCriteriaRangeModal] = useState(false)
  return (
    <div>
      <EditCriteriaNumber
        isEditCriteriaModalOpen={isEditCriteriaModalOpen}
        setCriteriaModal={setCriteriaModal}
      />
      <EditRangeCriteria 
      isRangeCriteriaModalOpen={isRangeCriteriaModalOpen}
      setCriteriaRangeModal={setCriteriaRangeModal}
      />
      <OtpModal />
      <p className="fw500 f12 fc4E596F">Criteria name - Maximum points</p>
      <section className="criteria-box mb24 d-flex-c-s px24">
        <div className="d-flex-c-f">
          <div className="box-id"> 1 </div>
          <div className="criteria-name ml24">
            <div className="text-primary fw600 f14">
              Age - <span className="fc4E596F">10</span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            20-25yrs - <span className="fc4E596F fw600">5</span>
          </div>
          <div className="f14 fw400">
            {" "}
            40-50yrs - <span>10</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            25-30yrs - <span className="fc4E596F fw600">5</span>
          </div>
          <div className="f14 fw400">
            50-55yrs -<span>10</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            30 - 40yrs - <span className="fc4E596F fw600">5</span>
          </div>
          <div className="f14 fw400">
            {" "}
            Above 55yrs - <span>0</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <button className="ylw-primary-btn px16 py8">
            <PreviewIcon />
          </button>
        </div>
      </section>
      <section className="criteria-box mb24 d-flex-c-s px24">
        <div className="d-flex-c-f">
          <div className="box-id"> 2 </div>
          <div className="criteria-name ml24">
            <div className="text-primary fw600 f14">
              Company - <span className="fc4E596F">10</span>
            </div>
          </div>
        </div>

        <div>
          <div className="f14 fw400">
            {" "}
            Non CAT - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div>
          <div className="f14 fw400">
            {" "}
            Other D/E/F - <span className="fc4E596F fw600">7</span>
          </div>
        </div>

        <div>
          <div className="f14 fw400">
            {" "}
            CAT A/B/C - <span className="fc4E596F fw600">10</span>
          </div>
        </div>

        <div>
          <button
            onClick={() => setCriteriaModal(true)}
            className="ylw-primary-btn px16 py8"
          >
            <PreviewIcon />
          </button>
        </div>
      </section>

      <section className="criteria-box mb24 d-flex-c-s px24">
        <div className="d-flex-c-f">
          <div className="box-id"> 3 </div>
          <div className="criteria-name ml24">
            <div className="text-primary fw600 f14">
              Salary - <span className="fc4E596F">10</span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            20-25K - <span className="fc4E596F fw600">5</span>
          </div>
          <div className="f14 fw400">
            {" "}
            40-50k - <span>10</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            25-30k - <span className="fc4E596F fw600">5</span>
          </div>
          <div className="f14 fw400">
            50-55k -<span>10</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400 mb10">
            {" "}
            30 - 40k - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <button onClick={() => setCriteriaRangeModal(true)} className="ylw-primary-btn px16 py8">
            <PreviewIcon />
          </button>
        </div>
      </section>

      <section className="criteria-box mb24 d-flex-c-s px24">
        <div className="d-flex-c-f">
          <div className="box-id"> 4 </div>
          <div className="criteria-name ml24">
            <div className="text-primary fw600 f14">
              Residence - <span className="fc4E596F">10</span>
            </div>
          </div>
        </div>

        <div>
          <div className="f14 fw400">
            {" "}
            Rented - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div>
          <div className="f14 fw400">
            {" "}
            Owned - <span className="fc4E596F fw600">7</span>
          </div>
        </div>

        <div></div>

        <div>
          <button className="ylw-primary-btn px16 py8">
            <PreviewIcon />
          </button>
        </div>
      </section>

      <section className="criteria-box mb24 d-flex-c-s px24">
        <div className="d-flex-c-f">
          <div className="box-id"> 5 </div>
          <div className="criteria-name ml24">
            <div className="text-primary fw600 f14">
              Years in residence - <span className="fc4E596F">10</span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400">
            {" "}
            0-5yrs - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400">
            {" "}
            25-30yrs - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <div className="f14 fw400">
            {" "}
            30 - 40yrs - <span className="fc4E596F fw600">5</span>
          </div>
        </div>

        <div className="d-flex flex-col">
          <button className="ylw-primary-btn px16 py8">
            <PreviewIcon />
          </button>
        </div>
      </section>
    </div>
  );
};

export default ScoringCriteria;
