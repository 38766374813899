import React from 'react';
import cx from 'classnames';
const noop = {};

const Pagination = (props) => {
  const { rowCount, onRowsPerPageChange, className } = props;

  const classes = cx('d-flex-c-e pt6 pr12 ', className);
  

  return (
    <div className={classes}>
     <p className='f12 mr6'> Show: </p> 
        <select
          className="pagination-select"
          onChange={onRowsPerPageChange}
          defaultValue={rowCount}
        >
          <option value="15">15 results per page</option>
          <option value="25">25 results per page</option>
          <option value="50">50 results per page</option>
          {/* {rowCount >= 15 && <option value="15">15 results per page</option>}
          {rowCount >= 25 && <option value="25">25 results per page</option>}
          {rowCount >= 50 && <option value="50">50 results per page</option>} */}
        </select>
    </div>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  rowsPerPage: 10,
  rowCount: 0,
  showPerPageSelect: true,
  showAllOption: true,
  onChangePage: noop,
  onChangeRowsPerPage: noop,
  className: '',
};

export default Pagination;
