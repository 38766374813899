import React from "react";
import Layout from "../../components/Layout";
import CustomTabs from "../../components/CustomTabs";
import "./internalScore.scss";
import ScoringCriteria from "./ScoringCriteria";
import LoanCriteria from "./LoanCriteria";

const Overview = () => {
  
  const TABS = [
    {
      id: "ScoringCriteria",
      title: "Scoring Criteria",
      lazyRender: true,
      component: ScoringCriteria,
    },
    {
      id: "loanCriteria",
      title: "Loan Criteria",
      lazyRender: true,
      component: LoanCriteria,
    },
  ];
  return (
    <Layout>
      <p className="header-text"> Edit Internal Score </p>
      <div className="internal-score-wrap px24">
        <p className="fw600 f16">SCORING CRITERIA</p>
        <CustomTabs
          variant="fullWidth"
          className="custom-tabs-indicator-ui"
          onChange={() => {}}
          defaultActiveIndex={0}
          tabs={TABS}
        />
      </div>
    </Layout>
  );
};

export default Overview;
