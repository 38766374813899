import React from "react";
import SearchBar from "../../SearchBar";
import cx from "classnames";

const BanksList = ({ handleNavClick, bankListArr, activeBank }) => {
  return (
    <div className="bank-list p16">
      <SearchBar placeholder="Search Bank" className="search-bank-input" iconClassName="bank-search-icon" />

      <div className="bank-names pl4">
        {bankListArr.map((bank) => {
          return (
            <div
              onClick={() => handleNavClick(bank)}
              className={cx("mb16 f14 fc4E596F fw600 cur-p", {
                isActive: activeBank === bank,
              })}
            >
              {bank}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BanksList;
