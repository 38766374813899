export const authUrls = {
    loginPath: '/login',
  };

export const rootUrls = {
    homePath:'/',
    usersPath: '/users',
    userDetailsPath: '/users/:id',
    notFoundPath: '/not-found',
    internalScorePath: '/internal-score',
    transactionPath: '/transactions',
    billingPath: '/billing',
    merchantPath: '/merchant',
    settingsPath: '/settings'
  };  
