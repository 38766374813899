import React from 'react';
import './shimmer.scss'

 const Shimmer = (props) => {
  const { useRandomWidth, height, width, className, maxWidth, customWidth } = props;
  let wt = useRandomWidth ? width * Math.random() * (3 - 0.7) + 0.7 : width;
  if (wt > maxWidth) {
    wt = width;
  }
  return (
    <span
      className={`shimmer ${className}`}
      style={{
        height: `${height}px`,
        width: `${customWidth ? customWidth : `${wt}px` }`,
        display: 'inline-flex',
      }}
    />
  );
 };

export default Shimmer;
