import React from 'react'
import Layout from '../../components/Layout'


const Billing = () => {
  return (
    <Layout>Billing</Layout>
  )
}

export default Billing