import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { CrossIcon, DiscardIcon, SaveBtnIcon } from "../../../assets/svgIcon";

const EditRangeCriteria = ({ isRangeCriteriaModalOpen, setCriteriaRangeModal }) => {
  const [rangeFields, setRangeFields] = useState([{ lowerRange: '', higherRange: '', score: '' }])

  const handleRangeChange = (event, index) => {
    let data = [...rangeFields];
    data[index][event.target.name] = event.target.value;
    setRangeFields(data);
  }

  const handleRangeSubmit = (e) => {
    e.preventDefault();
    console.log(rangeFields)
  }

  const addRangeField = () => {
    let rangeObject = {
      lowerRange: '',
      higherRange: '',
      score: ''
    }
    setRangeFields([...rangeFields, rangeObject])
  }

  const removeFields = (index) => {
    let tempData = [...rangeFields];
    tempData.splice(index, 1)
    setRangeFields(tempData)
  }


  return (
    <Modal maxWidth="sm" open={isRangeCriteriaModalOpen}>
      <div className="edit-criteria-modal">

        <div className="f16 d-flex-c-c fw600 mb24">
          <div className="fw600">Edit criteria </div>
          <CrossIcon
            onClick={() => {
                setCriteriaRangeModal(false);
            }}
            className="cross-icon"
          />
        </div>

        <div className="edit-criteria-box">
          <div className="disabled-input-ui text-primary">Salary</div>
          <input className="edit-input" value={10} />
        </div>

        <div className="edit-criteria-grid fcA7A9BE my24 f12">
           <div> Lower range  </div>
           <div className="ml12"> Higher range </div>
           <div className="ml24"> Score</div>
        </div>

        <form onSubmit={handleRangeSubmit}>
          {rangeFields.map((form, index) => {
           return (
            <div key={index} className="edit-criteria-grid  edit-criteria-box mb10">
            <input name='lowerRange' onChange={event => handleRangeChange(event, index)} className="range-input mr12" value={form.name} />
            <input name='higherRange' onChange={event => handleRangeChange(event, index)} className="range-input mr12" value={form.age} />
            <input name='score' onChange={event => handleRangeChange(event, index)}  className="number-input"  value={form.score} />
            <div onClick={() => removeFields(index)} className="delete-btn d-flex-c-c"> 
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M4.61816 3.25C4.61816 2.65326 4.85522 2.08097 5.27717 1.65901C5.69913 1.23705 6.27143 1 6.86816 1V1C7.4649 1 8.0372 1.23705 8.45915 1.65901C8.88111 2.08097 9.11816 2.65326 9.11816 3.25V3.25M4.61816 3.25H9.11816M4.61816 3.25H2.36816M9.11816 3.25H11.3682M12.8682 3.25H11.3682M0.868164 3.25H2.36816M2.36816 3.25V11.5C2.36816 11.8978 2.5262 12.2794 2.8075 12.5607C3.08881 12.842 3.47034 13 3.86816 13H9.86816C10.266 13 10.6475 12.842 10.9288 12.5607C11.2101 12.2794 11.3682 11.8978 11.3682 11.5V3.25" stroke="#242A38" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
          </div>
          )})}
        </form>

        <div className="d-flex-c-c mt56">
          <button onClick={addRangeField} className="ylw-primary-outline-btn px48 mx-auto py8 f14 d-flex-c-c">        
            <svg className="mr6" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.36816 12.834V6.83398M7.36816 6.83398V0.833984M7.36816 6.83398H13.3682M7.36816 6.83398H1.36816" stroke="url(#paint0_linear_2047_2737)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><defs><linearGradient id="paint0_linear_2047_2737" x1="13.3682" y1="0.833984" x2="1.36816" y2="12.834" gradientUnits="userSpaceOnUse"><stop stop-color="#F78361"/><stop offset="1" stop-color="#F54B64"/></linearGradient></defs></svg>
            Add criteria condition
          </button>
        </div>

        <div className="d-flex-c-c mt20">
          <button className="d-flex-c-c ylw-secondary-btn mr12 fw500 discard-btn">
            <DiscardIcon className="mr8" /> Discard
          </button>
          <button onClick={handleRangeSubmit} className="d-flex-c-c ylw-primary-btn px24 py10 fw500">
            <SaveBtnIcon className="mr8" /> Save
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default EditRangeCriteria;
