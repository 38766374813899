export const setItem = (key, value) => window.localStorage.setItem(key, value);

export const getItem = (key) => window.localStorage.getItem(key);

export const removeItem = (key) => window.localStorage.removeItem(key);

export const clearStorage = () => window.localStorage.clear();

export const clearSessionStorage = () => window.sessionStorage.clear();

export const getDataFromLocalStorage = (storagekey, defaultData = {}) => {
    try {
      return JSON.parse(getItem(storagekey)) || defaultData;
    } catch (e) {
      return defaultData;
    }
  };
  
  export const setDataToLocalStorage = (storagekey, storageHash) => {
    setItem(storagekey, JSON.stringify(storageHash));
  };