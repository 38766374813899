import React from "react";
import { FormHelperText } from "@material-ui/core";

export const FieldInfo = ({ helpText }) => {
  if (helpText) {
    return (
      <FormHelperText className="field-info">{helpText}</FormHelperText>
    );
  }
  return null;
};
