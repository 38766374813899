import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Header from './Header';
import MenuSidebar from './MenuSidebar';
import './layout.scss'

const Layout = ({
  children,
  className,
  documentTitle,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen);
  const classes = cx('dash-layout-content', className);

  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle;
    }
  }, [documentTitle]);

  return (
    <div className='dash-layout'>
      <Header />
      <MenuSidebar
        sidebarOpen={sidebarOpen}
        handleSidebarToggle={handleSidebarToggle}
      />
      <main className={cx(classes)}>
        {children}
      </main>
    </div>
  );
};

Layout.defaultProps = {
  headerText: '',
  className: '',
  documentTitle: 'Yelow | Dashboard',
};

Layout.propTypes = {
  headerText: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  documentTitle: PropTypes.string.isRequired,
};

export default React.memo(Layout);
