import React, { useState } from "react";
import Modal from "../../../components/Modal";
import "./filters.scss";
import Switch from "../../../components/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ResetIcon, SaveIcon, CrossIcon } from "../../../assets/svgIcon";

const Filters = ({isFilterModalOpen, setFilterModal}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  return (
    <Modal maxWidth="sm" open={isFilterModalOpen}>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div>Apply Filters </div>
        <CrossIcon onClick={() => setFilterModal(false)} className="cross-icon" />
      </div>
      
      {/* Credit Score */}
      <div className="d-flex-c-s mb24">
        <div className="fw600 f14">Credit Score</div>
        <div>
          <input className="score-input mr12" placeholder="From score" />
          <input className="score-input" placeholder="To score" />
        </div>
      </div>

      <div className="d-flex-c-s mb24">
        <div className="fw600 f14">Internal Score</div>
        <div>
          <input className="score-input mr12" placeholder="From score" />
          <input className="score-input" placeholder="To score" />
        </div>
      </div>

      {/* Account status */}
      <div>
        <div className="fw600 f14 mb14">Account status</div>
        <div className="status-options ml24">
          <div className="d-flex-c-f mb12">
            <div className="green-dot mr10"></div>
            <div className="f12 fc4E596F mr32"> Approved </div>
            <Switch checked={true} />
          </div>
          <div className="d-flex-c-f mb12">
            <div className="yellow-dot mr10"></div>
            <div className="f12 fc4E596F mr42"> Freezed </div>
            <Switch />
          </div>
          <div className="d-flex-c-f">
            <div className="red-dot mr10"></div>
            <div className="f12 fc4E596F mr42"> Blocked </div>
            <Switch />
          </div>
        </div>
      </div>

      {/* Add Date Picker */}
      <div className="d-flex-c-s mt24">
        <div className="fw600 f14">Date</div>

        <div className="d-flex-c-f">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Pick from date"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="Pick to date"
          />
        </div>
      </div>

      <div className="d-flex-c-c mt36">
        <button className="mr12 reset-btn d-flex-c-c">
          <ResetIcon className="mr6" /> Reset
        </button>
        <button className="save-btn d-flex-c-c">
          <SaveIcon className="mr6" /> Save
        </button>
      </div>
    </Modal>
  );
};

export default Filters;
