import React from "react";
import cx from "classnames";
import { FieldInfo } from "./Shared";

const FormGroup = (props) => {
  const { labelText, required, size, className, helpText, children } = props;
  const wrapperClasses = cx(
    "form-group",
    className,
    `form-group-${size}`
  );
  const labelClasses = cx("field-label", { required });

  return (
    <div className={wrapperClasses}>
      {!!labelText && <label className={labelClasses}>{labelText}</label>}
      {children}
      {!!helpText && <FieldInfo helpText={helpText} />}
    </div>
  );
};

FormGroup.defaultProps = {
  labelText: "",
  required: false,
  size: "lg", // md
  className: "",
  helpText: "",
};

export default FormGroup;
