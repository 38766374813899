import React, { useEffect } from "react";
import "./auth.scss";
import Login from "./Login";
import {useFirstAccessibleRoute} from "../../hooks/useFirstAccessibleRoute";

const Auth = () => {
  useFirstAccessibleRoute()

  return <Login />;
};

export default Auth;
