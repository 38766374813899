import React from "react";
import { CreditScoreIcon } from "../../../../assets/svgIcon";
import AssertDetails from "./AssertDetails";

const CreditDetails = () => {
  return (
    <div className="retail-account">
      <section className="top-header">
        <div className="summary px20 py10">
          <p className="m0 header-text">Enquiry summary</p>
          <div className="assert-details-container">
            <div>
              <AssertDetails
                className="pt12"
                title={`Total :`}
                information={`2`}
              />
              <AssertDetails title={`Recent :`} information={`04-08-2022`} />
              <AssertDetails title={`Purpose :`} information={`ALL`} />
            </div>

            <div>
              <AssertDetails
                title={`Past 30 days :`}
                information={`1`}
                className="pt12 grid-row-2-1"
              />
              <AssertDetails
                title={`Past 12 months :`}
                information={`2`}
                className="grid-row-2-1"
              />
              <AssertDetails
                title={`Past 24 months :`}
                information={`3`}
                className="grid-row-2-1"
              />
            </div>
          </div>
        </div>

        <div className="enquiry px20 py10">
          <section>
            <div>
              <p className="m0 header-text">Scoring details</p>
              <p className="fc4E596F f12 mb14">Number of product trades</p>
              <p className="fc4E596F f12 mb14">
                Sanctioned amount of all trades
              </p>
              <p className="fc4E596F f12 mb14">
                Insufficient info on or lack of home loan trades
              </p>
              <p className="fc4E596F f12 mb14">Balance amount of all trades</p>
            </div>

            <div>
              <div className="d-flex-c-f mb8 mt22">
                <p className="type">Type : RES</p>
              </div>

              <div className="d-flex-c-f mb8">
                <p className="type">Type : RES</p>
                <p className="type">Code : 8a</p>
              </div>

              <div className="d-flex-c-f mb8">
                <p className="type">Type : RES</p>
              </div>

              <div className="d-flex-c-f mb8">
                <p className="type">Type : RES</p>
              </div>
            </div>

            <div className="">
              <div className="d-flex-c-f">
                <CreditScoreIcon />
                <div className="ml8">
                  <p className="pb4 m0 f14">Credit Score</p>
                  <p className="m0 f16 fw600">721</p>
                </div>
              </div>

              <div className="text-primary f20 mt12 fw700">M001</div>

              <div>
                <p className="type-primary">Type : RES</p>
                <p className="type-primary">Type : RES</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="footer-retail-account px20 py10">
        <p className="m0 header-text">Retail accounts</p>

        <div className="container">
          <div>
            <AssertDetails
              title={`No. of accounts :`}
              information={`1`}
              className="pt12 grid-row-1-1"
            />
            <AssertDetails
              title={`No. of active accounts :`}
              information={`1`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Recent account :`}
              information={`Auto loan on 30-01-2022`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Oldest account :`}
              information={`Auto loan on 30-01-2022`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Total past due :`}
              information={`₹ 0.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`No. of past due accounts :`}
              information={`0`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`No. of zero balance accounts :`}
              information={`0`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`No. of write offs :`}
              information={`0`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Most severe status within 24 months :`}
              information={`Non-Delnqt`}
              className="grid-row-1-1"
            />
          </div>

          <div>
            <AssertDetails
              title={`Total high credit :`}
              information={`₹ 0.00`}
              className="pt12 grid-row-1-1"
            />
            <AssertDetails
              title={`Total credit limit :`}
              information={`₹ 0.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Single highest credit :`}
              information={`₹ 0.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Average open balance :`}
              information={`₹ 35124.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Single highest balance :`}
              information={`₹ 35124.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Total balance amount :`}
              information={`₹ 35124.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Total sanction amount :`}
              information={`₹ 35124.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Single highest sanction amount :`}
              information={`₹ 35124.00`}
              className="grid-row-1-1"
            />
            <AssertDetails
              title={`Total monthly payment amount :`}
              information={`Non-Delnqt`}
              className="grid-row-1-1"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreditDetails;
