import React, { useState } from "react";
import "./documents.scss";
import {
  HorizontalIcon,
  SuccessDotIcon,
  PhoneCallIcon,
  AddressesIcon
} from "../../../../assets/svgIcon";
import AddressInfo from './AddressInfo'

const Documents = () => {
  const [isAddressInfoOpen, setAddressInfo] = useState(false);

  return (
    <div className="documents-wrap px16">
      <p className="fw600 f20">DOCUMENTATION</p>
      <AddressInfo  isAddressInfoOpen={isAddressInfoOpen} setAddressInfo={setAddressInfo} />
      <div className="d-flex-f-f">
        <section>
          <div className="kyc-info px20 py12">
            <div className="d-flex-c-f">
              <p className="f14 fw700">KYC Info</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500">CKYC status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700">Success</p>
            </div>

            <div className="d-flex-c-f">
              <p className="f12 fc4E596F mb8 title-desc">CKYC date :</p>
              <p className="text-primary f14 fw600 mb8">28-06-2018</p>
            </div>

            <div className="d-flex-c-f">
              <p className="f12 fc4E596F mb8 title-desc">CKYC updated date :</p>
              <p className="text-primary f14 fw600 mb8">14-07-2020</p>
            </div>
          </div>

          <div className="pan-card px20 py12 mt24">
            <div className="d-flex-c-f">
              <p className="f14 fw700">PAN Card</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500">PAN status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700">Valid</p>
            </div>

            <div className="d-flex-c-f">
              <p className="f12 fc4E596F mb8 title-desc">PAN number :</p>
              <p className="text-primary f14 fw600 mb8">EWEPP0593R</p>
            </div>
          </div>
        </section>

        <section className="mx20">
          <div className="adhaar-card px20 py12">
            <div className="d-flex-c-f">
              <p className="f14 fw700">Aadhaar Card</p>
              <HorizontalIcon className="mx20" />
              <p className="f12 fw500">Aadhaar status</p>
              <SuccessDotIcon className="mx12" />
              <p className="f12 fw700">Valid</p>
            </div>

            <div className="d-flex-c-f">
              <p className="f12 fc4E596F mb8 title-desc">Aadhaar number :</p>
              <p className="text-primary f14 fw600 mb8">3304 4322 9141</p>
            </div>

            <div className="d-flex-c-f">
              <p className="f12 fc4E596F mb8 title-desc">
                Aadhaar reference no. :
              </p>
              <p className="text-primary f14 fw600 mb8">
                914120220509085252912
              </p>
            </div>

            <div onClick={() => setAddressInfo(true)} className="adhaar-address-info d-flex-c-c mx-auto f12 fw400 text-center mt16">
              View aadhaar address info
            </div>
          </div>
        </section>

        <section>
          <div className="phone-numbers px20 py12">
            <div className="d-flex-c-s">
              <p className="f14 fw700">Phone Numbers</p>
              <p className="f12 fcA7A9BE">Type code</p>
            </div>

            <div className="d-flex-c-f">
              <div className="d-flex-c-c">
                <PhoneCallIcon className="mr14" />
                <div>
                  <p className="f14 fw700 text-primary mb0">9685412365</p>
                  <p className="f12 fw500 fc4E596F mt8">
                    Reposted date : 30-11-2020
                  </p>
                </div>
              </div>

              <div className="fw700 f16 ml72">H</div>
            </div>

            <div className="d-flex-c-f">
              <div className="d-flex-c-c">
                <PhoneCallIcon className="mr14" />
                <div>
                  <p className="f14 fw700 text-primary mb0">9685412365</p>
                  <p className="f12 fw500 fc4E596F mt8">
                    Reposted date : 30-11-2020
                  </p>
                </div>
              </div>

              <div className="fw700 f16 ml72">H</div>
            </div>

            <div className="d-flex-c-f">
              <div className="d-flex-c-c">
                <PhoneCallIcon className="mr14" />
                <div>
                  <p className="f14 fw700 text-primary mb0">9685412365</p>
                  <p className="f12 fw500 fc4E596F mt8">
                    Reposted date : 30-11-2020
                  </p>
                </div>
              </div>

              <div className="fw700 f16 ml72">H</div>
            </div>
          </div>
        </section>
      </div>

      <div className="mt36 pb24">
          <p className="f14 fw600">Addresses</p>

          <div className="d-flex-f-f">
              <AddressesIcon />
              <div className="ml14">
                  <p className="m0 f12 fw700">S/O Dnyaneshvar Pavade Maldhamani PO Hingoli Hingoli MAHARASHTRA 453212 Pharbhani MAHARASHTRA453212, MH</p>
                  <p className="my8 f12 fw400 fc4E596F">Postal code : 453212</p>
                  <p className="m0 f12 fw400 fc4E596F">Reposted date : 30-11-2020</p>
              </div>
              <div className="ml-auto text-primary f12">
                 Owns, Permanent
              </div>
          </div>

          <div className="d-flex-f-f my30">
              <AddressesIcon />
              <div className="ml14">
                  <p className="m0 f12 fw700">S/O Dnyaneshvar Pavade Maldhamani PO Hingoli Hingoli MAHARASHTRA 453212 Pharbhani MAHARASHTRA453212, MH</p>
                  <p className="my8 f12 fw400 fc4E596F">Postal code : 453212</p>
                  <p className="m0 f12 fw400 fc4E596F">Reposted date : 30-11-2020</p>
              </div>
              <div className="ml-auto text-primary f12">
                 Owns, Permanent
              </div>
          </div>

          <div className="d-flex-f-f">
              <AddressesIcon />
              <div className="ml14">
                  <p className="m0 f12 fw700">S/O Dnyaneshvar Pavade Maldhamani PO Hingoli Hingoli MAHARASHTRA 453212 Pharbhani MAHARASHTRA453212, MH</p>
                  <p className="my8 f12 fw400 fc4E596F">Postal code : 453212</p>
                  <p className="m0 f12 fw400 fc4E596F">Reposted date : 30-11-2020</p>
              </div>
              <div className="ml-auto text-primary f12">
                 Owns, Permanent
              </div>
          </div>
      </div>
    </div>
  );
};

export default Documents;
