import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { CrossIcon, SaveBtnIcon } from "../../../assets/svgIcon";
import OtpInput from "react-otp-input";

const EditRangeCriteria = ({ isOtpModalOpen=false, setOtpModal }) => {
  const [otp, setOtp] = useState("");
  const handleOtpChange = (otp) => setOtp(otp);

  return (
    <Modal maxWidth="sm" open={isOtpModalOpen}>
      <div className="edit-criteria-modal">
        <div className="f16 d-flex-c-c fw600 mb24">
          <div className="fw600">OTP verification</div>
          <CrossIcon
            onClick={() => {
              setOtpModal(false);
            }}
            className="cross-icon"
          />
        </div>

        <div className="otp-text">
          Enter the OTP sent on the number XXXXXXXX89 for <br /> saving the
          changes
        </div>

        <div className="mt48 d-flex-c-c">
          <OtpInput
            value={otp}
            onChange={handleOtpChange}
            numInputs={6}
            separator={<span className="mr6"></span>}
            inputStyle={{
              width: "2em",
              height: "2em",
              border: "1px solid #A7A9BE",
              borderRadius: "5px",
            }}
          />
        </div>

        <div className="otp-timer text-primary fw600 d-flex-c-c mb48 mt12"> 4:59 </div>

        <div className="d-flex-c-c mt20">
          <button className="d-flex-c-c ylw-secondary-btn mr12 fw500 discard-btn">
            Resend
          </button>
          <button className="d-flex-c-c ylw-primary-btn px24 py10 fw500">
            <SaveBtnIcon className="mr8" /> Verify
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditRangeCriteria;
