import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {PhoneIcon} from '../../../assets/svgIcon'
import StepConnector from '@material-ui/core/StepConnector';
import {UpiIcon} from '../../../assets/svgIcon'



const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
    background:'transparent',
    fontFamily: 'Raleway'
  },
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#4E596F',
    borderRadius: 1,
  },
 
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    border: '2px solid #4E596F',
    zIndex: 1,
    color: '#fff',
    background:'transparent',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    border: '2px solid #F54B64',
    background: 'transparent',
  
  },
  completed: {
    background: 'transparent',
    border: '2px solid #F54B64',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PhoneIcon />,
    2: <PhoneIcon />,
    3: <PhoneIcon />,
    4: <PhoneIcon />,
    5: <PhoneIcon />,
    6: <PhoneIcon />,
    7: <UpiIcon />,
    8: <UpiIcon />,
    9: <UpiIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}


function getSteps() {
  // [
  //   "PHONE_NUMBER_INPUT",
  //   "WHATSAPP_VERIFICATION",
  //   "PERSONAL_INFO",
  //   "PAN_VERIFICATION",
  //   "SELFIE",
  //   "AADHAR_VERIFICATION",
  //   "CREDIT_OFFERED",
  //   "UPI_MANDATE",
  //   "ONBOARDING_SUCCESS"
  // ];
  return ['Phone Number Input', 'Whatsapp verification', 'Personal Info', 'Pan Verification', 'Selfie', 'Adhaar Verification', 'Credit Offered', 'Upi Mandate', 'Onboarding Success'];
}

 const  CustomizedSteppers = () => {
  const [activeStep, setActiveStep] = React.useState(5);
  const steps = getSteps();



  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>
  );
}



const OnboardingStatus = () => {
  return (
    <div className="onboarding-status-stepper mt24 mb24 px24 py12">
      <p className="d-flex-c-c f14 fw600">Onboarding status</p>
      <CustomizedSteppers />
    </div>
  );
};

export default OnboardingStatus;
