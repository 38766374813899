

import React, { memo, useState, useEffect } from 'react';
import cx from 'classnames';
import { Tab, Tabs } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { noop } from '../../utils';
import './tabs.scss'

const TabContainer = (props) => {
  const [isLoaded, setLoaded] = useState(false);
  const {
    component: WrappedComponent,
    lazyRender,
    isActiveTab,
    className,
    componentProps,
  } = props;

  useEffect(() => {
    if (!isLoaded) {
      const canRender = lazyRender ? isActiveTab : true;
      setLoaded(canRender);
    }
  }, [isLoaded, lazyRender, isActiveTab]);

  const classes = cx('tab-container', className, {
    'active-tab-container': isActiveTab,
    'inactive-tab-container': !isActiveTab,
  });

  return (
    <div className={classes}>
      {isLoaded && <WrappedComponent {...componentProps} />}
    </div>
  );
};

const SwipeableTabContent = (props) => {
  const { setTabIndex, tabs, tabIndex } = props;
  const handleChangeIndex = (index) => setTabIndex(index);
  
  return (
    <SwipeableViews
      className="tab-content"
      axis="x"
      index={tabIndex}
      onChangeIndex={handleChangeIndex}
    >
      {tabs.map((tab, index) => (
        <TabContainer
          isActiveTab={tabIndex === index}
          key={`tab_container_${tab.title}`}
          {...tab}
        />
      ))}
    </SwipeableViews>
  );
};

const ToggleTabContent = (props) => {
  const { tabs, tabIndex } = props;
  return (
    <div>
      {tabs.map((tab, index) => (
        <TabContainer
          isActiveTab={tabIndex === index}
          key={`tab_container_${tab.title}`}
          {...tab}
        />
      ))}
    </div>
  );
};

const CustomTabs = memo((props) => {
  const {
    className,
    defaultActiveIndex,
    tabs,
    onChange,
    variant,
    tabContentDisplayType,
  } = props;
  const [tabIndex, setTabIndex] = useState(defaultActiveIndex);
  
  const handleChange = (_, index) => {
    onChange(index, tabs[index]);
    setTabIndex(index);
  };


  return (
    <div className={`tab-wrap ${className}`}>
      <Tabs variant={variant} value={tabIndex} onChange={handleChange}>
        {tabs.map((tab) => {
          const { title, disabled = false } = tab;
          return (
            <Tab 
              disableRipple
              key={`tab_${title}`}
              label={title}
              disabled={disabled}
            />
          );
        })}
      </Tabs>
      {tabContentDisplayType === 'swipeable' && (
        <SwipeableTabContent
          tabs={tabs}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
        />
      )}
      {tabContentDisplayType === 'toggle' && (
        <ToggleTabContent tabs={tabs} tabIndex={tabIndex} />
      )}
    </div>
  );
});

CustomTabs.displayName = 'CustomTabs';

CustomTabs.defaultProps = {
  className: '',
  defaultActiveIndex: 0,
  tabs: [],
  variant: '',
  onChange: noop,
  tabContentDisplayType: 'swipeable',
};

export default CustomTabs;