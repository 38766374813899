import { MIDDLEWARE_ORIGIN } from "../constants";

export const authApiUrls = {
  loginPath: `${MIDDLEWARE_ORIGIN}manpower/yelow/login`,
};

export const userApiUrls = {
  // TODO: make query param dynamic
  listPath: `${MIDDLEWARE_ORIGIN}user/list`,
  detailsPath: `${MIDDLEWARE_ORIGIN}user/admin/user_profile`,
  updateProfileStatusPath: `${MIDDLEWARE_ORIGIN}user/profile/status`,
  uploadPath: `${MIDDLEWARE_ORIGIN}user/upload`,
  profilePath:  `${MIDDLEWARE_ORIGIN}user/admin/user_profile`,
}