import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { CrossIcon } from "../../../../assets/svgIcon";

const AddressInfoTextBox = ({ assert='', info='' }) => {
  return (
    <div style={{minHeight:'20px'}} className="d-flex-c-f pb16">
      <p style={{minWidth:'100px'}} className={`fw500 f12 m0 text-ellipsis`}>{assert}</p>
      <p className={`fw700 f14 m0 text-primary`}>{info}</p>
    </div>
  );
};

const AdhaarInfoModal = ({isAddressInfoOpen, setAddressInfo}) => {
  return (
    <Modal maxWidth="sm" open={isAddressInfoOpen}>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div>Address info </div>
        <CrossIcon
          onClick={() => {
           setAddressInfo(false)
          }}
          className="cross-icon"
        />
      </div>
      <div style={{maxWidth: '60%'}}>
      <AddressInfoTextBox assert={`VTC :`} info={`Batala`} />
      <AddressInfoTextBox assert={`Care of :`} info={`S/O Raj Kumar`} />
      <AddressInfoTextBox assert={`House :`} info={`113`} />
      <AddressInfoTextBox assert={`Locality :`} info={`Near DSP DI Kothi`} />
      <AddressInfoTextBox assert={`Street :`} info={`Green Avenue`} />
      <AddressInfoTextBox assert={`Landmark :`} info={`Circular Road`} />
      <AddressInfoTextBox assert={`District :`} info={`Gurdaspur`} />
      <AddressInfoTextBox assert={`Subdistrict`} info={`Batala`} />
      <AddressInfoTextBox assert={`State :`} info={`Punjab`} />
      <AddressInfoTextBox assert={`Pin code :`} info={`469815`} />
      <AddressInfoTextBox assert={`Post office :`} info={`Batala`} />
      </div>
    </Modal>
  );
};

export default AdhaarInfoModal;
