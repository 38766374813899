import React from "react";
import "./transactions.scss";
import Table from "../../../../components/Table";
import useColumns from "./useColumns";
import SearchBar from "../../SearchBar";

const data = [
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const Transactions = () => {
  const { columns } = useColumns();
  return (
    <div className="transactions-wrap">
      <div className="react-transactions-table">
        <div className="header-bar ">
          <div className="f16 fw700 mt24 ml24">TRANSACTIONS</div>
          <SearchBar  className={`ml20 mt12 `} iconClassName={`transactions-icon`} />
        </div>
        <Table
          serverSidePagination={false}
          isLoading={false}
          columns={columns}
          data={data}
          //   onRowClicked={onRowClick}
          //   onPageChange={onPageChange}
          //   totalCount={totalCount}
          //   currentPage={currentPage}
          //   pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default Transactions;
