import React, { useState } from "react";
import "./internalScore.scss";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import ScoringCriteria from './ScoringCriteria'

const InternalScore = () => {
  const [isScoringCriteriaBoxOpen, setScoringCriteriaBox] = useState(false)
  return (
    <div className="internal-score px16">
    <ScoringCriteria isScoringCriteriaBoxOpen={isScoringCriteriaBoxOpen} setScoringCriteriaBox={setScoringCriteriaBox} />
      <div className="d-flex-c-s">
      <p className="fw600 f20">INTERNAL SCORE</p>
      <button onClick={() => setScoringCriteriaBox(true)} className="scoring-criteria-btn">Scoring Criteria</button>
      </div>
      <div className="body d-flex-f-f">
        <div className="table">
          <header>
            <div class="col">Criterias</div>
            <div class="col ">User Eligiblity</div>
            <div class="col">Score</div>
          </header>
          <div class="row">
            <div class="col">Age</div>
            <div class="col text-primary fw600 ">Non Cat</div>
            <div class="col">5</div>
          </div>
          <div class="row">
            <div class="col">Salary</div>
            <div class="col text-primary fw600">₹45000</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">Residence</div>
            <div class="col text-primary fw600">Owned</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">Years in residence</div>
            <div class="col text-primary fw600">6 years</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">UID address</div>
            <div class="col text-primary fw600">Same as residence</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">CIBIL</div>
            <div class="col text-primary fw600">755</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">Derogatory in CIBIL</div>
            <div class="col text-primary fw600">No</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">DPD</div>
            <div class="col text-primary fw600">10</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">Active loan</div>
            <div class="col text-primary fw600">6</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">BSA</div>
            <div class="col text-primary fw600">Chq/Nach bounce = 2</div>
            <div class="col">10</div>
          </div>
          <div class="row">
            <div class="col">BSA avg. balance</div>
            <div class="col text-primary fw600">5500</div>
            <div class="col">10</div>
          </div>
        </div>

        <div className="score-and-loan">
          <div className="score">
            <div className="internal-score-box d-flex-c-f ml20">
              <div style={{ width: "48px", height: "48px" }}>
                <CircularProgressbarWithChildren
                  strokeWidth={6}
                  styles={buildStyles({
                    pathColor: "#F54B64",
                    rotation: 0.25,
                  })}
                  value={56}
                >
                  <svg
                    width="27"
                    height="27"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {" "}
                    <path
                      d="M25.503 11.4842C24.7168 6.2717 20.5976 2.15251 15.3857 1.36572M11.7462 1.36753C5.94782 2.24724 1.50342 7.25338 1.50342 13.2979C1.50342 19.3437 5.95024 24.3516 11.7522 25.2289C12.9557 25.4109 14.1797 25.4113 15.3833 25.2301C20.5976 24.4445 24.7186 20.3229 25.503 15.108"
                      stroke="#4E596F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />{" "}
                    <path
                      d="M13.5708 8.47095C12.2907 8.47095 11.0629 8.9795 10.1577 9.88472C9.25245 10.79 8.7439 12.0177 8.7439 13.2979C8.7439 14.5781 9.25245 15.8058 10.1577 16.711C11.0629 17.6163 12.2907 18.1248 13.5708 18.1248C14.851 18.1248 16.0788 17.6163 16.984 16.711C17.8892 15.8058 18.3978 14.5781 18.3978 13.2979C18.3978 12.0177 17.8892 10.79 16.984 9.88472C16.0788 8.9795 14.851 8.47095 13.5708 8.47095V8.47095Z"
                      stroke="#4E596F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </CircularProgressbarWithChildren>
              </div>

              <div className="ml20">
                <p className="pb8 m0 f14">Internal Score</p>
                <p className="m0 f16 fw600">96 / 130</p>
              </div>
            </div>{" "}
            {/* TODO: Add score here */}
          </div>

          <div className="loan-box ml20 mt20">
            <div className="loan-row d-flex-c-s">
               <p className="f12 fw500">Loan Demand</p>
               <p className="f12 text-primary fw600">₹25000</p>
            </div>
            <div className="loan-row d-flex-c-s">
               <p className="f12 fw500">Loan eligible</p>
               <p className="f12 text-primary fw600">₹19000</p>
            </div>
            <div className="loan-row d-flex-c-s">
               <p className="f12 fw500">Loan offered</p>
               <p className="f12 text-primary fw600">₹18000</p>
            </div>
            <div className="loan-row d-flex-c-s">
               <p className="f12 fw500">Tenure offered</p>
               <p className="f12 text-primary fw600">4</p>
            </div>
            <div className="loan-row d-flex-c-s">
               <p className="f12 fw500">Processing fees</p>
               <p className="f12 text-primary fw600">8%</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalScore;
