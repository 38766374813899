import {
    setDataToLocalStorage,
    getDataFromLocalStorage,
    setItem,
    getItem,
    clearStorage,
    clearSessionStorage,
  } from './storage';
  
  const USER_DETAIL_KEY = 'usr_detail';
  const AUTH_TOKEN_KEY = 'auth_token';
  
  export const setUserDetails = (details) => {setDataToLocalStorage(USER_DETAIL_KEY, details)};
  export const getUserDetails = () => getDataFromLocalStorage(USER_DETAIL_KEY);
  export const getUserRole = () => getUserDetails().type;
  
  export const setAuthToken = (token) => setItem(AUTH_TOKEN_KEY, token);
  export const getAuthToken = () => getItem(AUTH_TOKEN_KEY);
  
  export const isUserLoggedIn = () => !!getAuthToken();
  
  export const logoutUser = () => {
    // cleaning up both storage
    clearStorage();
    clearSessionStorage();
    window.location = "/login" // TODO replace it with proper value
  };
  