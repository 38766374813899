// Docs Link: https://react-table.tanstack.com/docs/overview

import React, {useEffect} from 'react'
import cx from "classnames";
import { useTable,useSortBy, useRowSelect, usePagination} from 'react-table'
import Pagination from './Pagination';
import Shimmer from '../Shimmer'


const CustomLoader = ({noOfLines}) => {
  return (
    <div style={{marginTop:'24px'}}>
      {[...Array(noOfLines)].map((e, i) => (
        <div style={{marginBottom:'16px'}} key={i}>
          <Shimmer height={45} />
        </div>
      ))}
    </div>
  );
};

const ReusableTable = (props) => {
  const {columns, data, getInitialTableData, serverSidePagination=false, isLoading=false, totalPages} = props
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  )

 useEffect(() => {
    if (rows.length) {
      getInitialTableData && getInitialTableData(rows)
    }
  }, [rows])
  

  return (
    <>
      <div className='table' {...getTableProps()}>
       <div className="header">
          {headerGroups.map(headerGroup => (
            <div 
            className='tr' {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => {
                return (
                  <div
                    className={cx('th d-flex-c-s')}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {isLoading ? <CustomLoader noOfLines={20} /> : rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <>
                <div className={cx('tr')} {...row.getRowProps({
                  onClick: e => props.onRowClicked && props.onRowClicked(row, e),
                })}>
                  {row.cells.map(cell => {
                    return (
                      <div className='td' {...cell.getCellProps()}>{cell.render('Cell')}</div>
                    )
                  })}
                </div>
               </>
              )}
          )}
        </div>
        {serverSidePagination ? <Pagination {...props} /> : ''}
      </div>
    </>
  )
}
  export default ReusableTable