import React from 'react'
import Layout from '../../components/Layout'


const Merchant = () => {
  return (
    <Layout>Merchant</Layout>
  )
}

export default Merchant