import React from "react";
import Modal from "../../../../components/Modal";
import { CrossIcon } from "../../../../assets/svgIcon";
import Transactions from "./Transactions";

const TransactionModal = ({ setTransactionModal, isTransactionModalOpen }) => {
  return (
    <Modal maxWidth="lg" open={isTransactionModalOpen}>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div className="fw600">TRANSACTIONS LIST </div>
        <CrossIcon
          onClick={() => {
            setTransactionModal(false);
          }}
          className="cross-icon"
        />
      </div>

      <Transactions />

    </Modal>
  );
};

export default TransactionModal;
