import React from "react";
import cx from "classnames";
import { Dialog, DialogContent } from "@material-ui/core";
import { noop } from "../../utils";

const Modal = (props) => {
  const {
    closeModal,
    children,
    actions,
    className,
    paperClass,
    maxWidth,
    ...restProps
  } = props;

  const dialogProps = {
    ...restProps,
    maxWidth,
    className: cx("dialog-modal", className),
    onClose: closeModal,
    classes: {
      paper: cx("dialog-paper", paperClass, maxWidth),
    },
    BackdropProps: {
      classes: {
        root: "dialog-backdrop",
      },
    },
  };

  return (
    <Dialog {...dialogProps}>
      <DialogContent className="dialog-content">{children}</DialogContent>
    </Dialog>
  );
};

Modal.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  scroll: "paper",
  fullWidth: false,
  maxWidth: "sm", // 'xs', 'md', 'lg', 'xl'
  paperClass: "",
  open: false,
  closeModal: noop,
  className: "",
};

export default Modal;
