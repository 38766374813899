import Router from './routes/router.js';
import ErrorBoundary from './ErrorBoundary.js';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import './styles/app.scss'


const App = () => {
  return (
    <ErrorBoundary>
       <SnackbarProvider
          dense
          TransitionComponent={Slide}
          hideIconVariant={false}
          maxSnack={2}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
      <Router />
      </SnackbarProvider>
    </ErrorBoundary>
  );
}

export default App;
