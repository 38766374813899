  import { LOGIN_USER_DATA } from "../types";
  import {getUserDetails} from '../../utils/user'


  const intitialState = {
    data: getUserDetails()
  };

  const reducer = (state = intitialState, action) => {
    switch (action.type) {
      case LOGIN_USER_DATA:
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };

  export default reducer
  