import { Avatar } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from "react-router-dom";
import {
  updateProfileStatus,
  fileUpload,
  updateProfileData,
} from "../../../adapters/users";
import { CreditLimitIcon, CreditScoreIcon } from "../../../assets/svgIcon";
import Switch from "../../../components/Switch";
import OnboardingStatus from "./OnboardingStatus";
import "./userDetails.scss";
import { UserIcon, SubmitIcon, CameraIcon } from "../../../assets/svgIcon";
import ImageUploading from "react-images-uploading";
import Tooltip from "../../../components/Tooltip";

const getRandomColor = (name) => {
  if (!name) return "#E8E8EB";

  let hex = Math.floor(Math.random() * 0xffffff);
  let color = "#" + hex.toString(16);

  return color;
};

const getPaymentCategoryAndModeOfPayment = (userDetails) => {
  let paymentCategory = "";
  let modeOfPayment = "";
  userDetails.payment_methods?.forEach((paymentMethod) => {
    const { payment_category, type } = paymentMethod;
    if (payment_category === "direct") {
      paymentCategory += "DIRECT ";
    } else if (payment_category === "pay_later") {
      paymentCategory += "PAY LATER ";
    } else if (payment_category === "upi") {
      paymentCategory += "UPI ";
    }

    // TODO: ask for other types if there are any
    if (type === "cashfree") {
      modeOfPayment = "CASHFREE";
    }
  });
  return { paymentCategory, modeOfPayment };
};

export const UserDetailsHeader = ({ userDetails }) => {
  return (
    <div className="account-details-header">
      <div className="account-details-header-left  d-flex-c-f">
        <p className="user-name fw600 f20">{userDetails?.first_name}</p>
        <svg
          className="mx12"
          width="2"
          height="32"
          viewBox="0 0 2 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.980469 31.3779V1.37793"
            stroke="#4E596F"
            stroke-width="0.8"
            stroke-linecap="round"
          />
        </svg>
        <p className="f12">Users</p>
        <svg
          className="mx6"
          width="8"
          height="15"
          viewBox="0 0 8 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.980468 1.62793L6.98047 7.62793L0.980469 13.6279"
            stroke="#0F0E17"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p className="f12 text-primary fw600">{userDetails?.first_name}</p>
      </div>

      <div className="account-details-header-right">
        {/* TODO: Add page control here */}
      </div>
    </div>
  );
};

const AvatarComponent = React.memo(({ images, userDetails }) => {
  return (
    <Avatar
      src={images[0]?.data_url || userDetails?.avatar_url}
      className="user-image"
      style={{
        backgroundColor: getRandomColor(userDetails?.first_name),
      }}
    >
      {userDetails?.first_name?.split("")[0]}
    </Avatar>
  );
});

const UserDetails = ({ userDetails }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  useEffect(() => {
    setUserName(`${userDetails?.first_name} ${userDetails?.last_name}`);
    setUserEmail(userDetails?.email);
    setUserPhone(userDetails?.phone_number);
  }, [userDetails]);

  const editUserProfile = (data) => {
    updateProfileData(data)
      .then((res) => {
        if (res.status) {
          enqueueSnackbar("Profile successfully updated", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const { paymentCategory, modeOfPayment } =
    getPaymentCategoryAndModeOfPayment(userDetails);
  const { is_waitlist, is_account_blocked, is_account_frozen } = userDetails;

  const [images, setImages] = React.useState([]);

  const onImageChange = (imageList) => {
    const formData = new FormData();
    formData.append("file", imageList[0].file);
    formData.append("type", "users");

    fileUpload(formData)
      .then((res) => {
        onUserDetailEdit({ avatar_url: res.payload.url, uuid: id });
      })
      .catch((err) => {
        console.log("err", err);
      });
    setImages(imageList);
  };

  const onChange = (value, name) => {
    let data = {
      [name]: value,
      user_id: id,
    };

    // @piyush is this logic correct?
    if (name === "is_waitlist") {
      data["is_waitlist"] = !value;
    }

    updateProfileStatus(data)
      .then(({ message, status }) => {
        if (status) {
          enqueueSnackbar(message, { variant: "success" });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onUserDetailEdit = (e, type) => {
    if (type === "userName") {
      setUserName(e.target.value);
    } else if (type === "userEmail") {
      setUserEmail(e.target.value);
    } else if (type === "userPhone") {
      setUserPhone(e.target.value);
    }
  };

  return (
    <div className="account-details-wrap">
      <div className="account-details-body px24">
        <div className="d-flex-c-f">
          <p className="fw600 f20">ACCOUNT DETAILS</p>
          <svg
            className="ml12 mr20"
            width="2"
            height="32"
            viewBox="0 0 2 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.980469 31.3779V1.37793"
              stroke="#4E596F"
              stroke-width="0.8"
              stroke-linecap="round"
            />
          </svg>
          <p className="f12">Account status</p>
          {userDetails?.onboarding_state ? (
            <>
              <svg
                className="mx8"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5.98047" cy="5.84619" r="5" fill="#33BF9E" />
              </svg>
              <p className="f12  fw600">Approved</p>
            </>
          ) : (
            <p className="f12 mx8 fw600">- Unknown</p>
          )}
        </div>

        <div className="mt24 d-flex-c-f ">
          <div className="profile d-flex-c-f">
            <div className="mr20 relative d-flex-c-c">
              <AvatarComponent images={images} userDetails={userDetails} />
              <ImageUploading
                multiple={false}
                value={images}
                onChange={onImageChange}
                dataURLKey="data_url"
                acceptType={["jpg", "png"]}
              >
                {({ onImageUpload, isDragging, dragProps }) => (
                  <button
                    className="camera-icon-container"
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <CameraIcon className="camera-icon" />
                  </button>
                )}
              </ImageUploading>
            </div>

            <div className="user-details mr24">
              <div className="d-flex-c-f mb8">
                <UserIcon className="mr20" />
                <input
                  onChange={(e) => onUserDetailEdit(e, "userName")}
                  className="name_user-details"
                  value={userName}
                />
                <button
                  onClick={() =>
                    editUserProfile({
                      first_name: userName.split(" ")[0],
                      last_name: userName.split(" ")[1] || "",
                      uuid: id,
                    })
                  }
                  disabled={!userName?.length}
                  className="submit-icon cur-p"
                >
                  <Tooltip title="Save">
                    <SubmitIcon className="ml24" />
                  </Tooltip>
                </button>
              </div>

              <div className="d-flex-c-f mb8">
                <UserIcon className="mr20" />
                <input
                  onChange={(e) => onUserDetailEdit(e, "userEmail")}
                  className="name_user-email"
                  value={userEmail}
                />
                <button
                  onClick={() =>
                    editUserProfile({ email: userEmail, uuid: id })
                  }
                  disabled={!userEmail?.length}
                  className="submit-icon cur-p"
                >
                  <Tooltip title="Save">
                    <SubmitIcon className="ml24" />
                  </Tooltip>
                </button>
              </div>

              <div className="d-flex-c-f mb8">
                <UserIcon className="mr20" />
                <input
                  onChange={(e) => onUserDetailEdit(e, "userPhone")}
                  className="name_user-email"
                  value={userPhone}
                />
                <button
                  onClick={() =>
                    editUserProfile({ phone_number: userPhone, uuid: id })
                  }
                  disabled={!userPhone?.length}
                  className="submit-icon cur-p"
                >
                  <Tooltip title="Save">
                    <SubmitIcon className="ml24" />
                  </Tooltip>
                </button>
              </div>

              <div className="d-flex-c-f mb8">
                <UserIcon className="mr20" />
                <p className="f12 m0 mb6">Refferal code - 78DF12</p>
              </div>
            </div>
          </div>

          <div className="user-account-status">
            <div>
              <div className="d-flex-c-f ">
                <svg
                  className="mr20"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 11.9264C18.28 15.5262 15.5658 18.9158 11.7572 19.6732C9.89971 20.0431 7.97284 19.8176 6.25097 19.0287C4.52911 18.2398 3.10002 16.9278 2.16721 15.2794C1.23439 13.6311 0.845398 11.7305 1.05561 9.84817C1.26583 7.96589 2.06454 6.19791 3.33801 4.79597C5.95002 1.91902 10.3605 1.12706 13.9603 2.56698"
                    stroke="#33BF9E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.76074 10.4864L10.3605 14.0862L19 4.72668"
                    stroke="#33BF9E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="f12 fw600 mr12">Approve account</p>
                <Switch
                  onChange={(value) => onChange(value, "is_waitlist")}
                  checked={!is_waitlist}
                />
              </div>

              <div className="d-flex-c-f ">
                <svg
                  className="mr20"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 10.856C19 12.0379 18.7672 13.2082 18.3149 14.3001C17.8626 15.392 17.1997 16.3842 16.364 17.2199C15.5282 18.0556 14.5361 18.7186 13.4442 19.1709C12.3522 19.6232 11.1819 19.856 10 19.856C8.8181 19.856 7.64778 19.6232 6.55585 19.1709C5.46392 18.7186 4.47177 18.0556 3.63604 17.2199C2.80031 16.3842 2.13738 15.392 1.68508 14.3001C1.23279 13.2082 1 12.0379 1 10.856C1 8.46901 1.94821 6.17982 3.63604 4.492C5.32387 2.80417 7.61305 1.85596 10 1.85596C12.3869 1.85596 14.6761 2.80417 16.364 4.492C18.0518 6.17982 19 8.46901 19 10.856Z"
                    stroke="#FFC227"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 8.85596C7 8.59074 7.10536 8.33639 7.29289 8.14885C7.48043 7.96131 7.73478 7.85596 8 7.85596H12C12.2652 7.85596 12.5196 7.96131 12.7071 8.14885C12.8946 8.33639 13 8.59074 13 8.85596V12.856C13 13.1212 12.8946 13.3755 12.7071 13.5631C12.5196 13.7506 12.2652 13.856 12 13.856H8C7.73478 13.856 7.48043 13.7506 7.29289 13.5631C7.10536 13.3755 7 13.1212 7 12.856V8.85596Z"
                    stroke="#FFC227"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="f12 fw600 mr22">Freeze account</p>
                <Switch
                  onChange={(value) => onChange(value, "is_account_frozen")}
                  checked={is_account_frozen}
                />
              </div>

              <div className="d-flex-c-f">
                <svg
                  className="mr20"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 19.856C14.9706 19.856 19 15.8265 19 10.856C19 5.88539 14.9706 1.85596 10 1.85596C5.02944 1.85596 1 5.88539 1 10.856C1 15.8265 5.02944 19.856 10 19.856Z"
                    stroke="#F55459"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.7002 17.1559L16.3002 4.55591"
                    stroke="#F55459"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="f12 fw600 mr28">Block account</p>
                <Switch
                  onChange={(value) => onChange(value, "is_account_blocked")}
                  checked={is_account_blocked}
                />
              </div>

              <div className="d-flex-c-f">
                <p className="f12 fw500 mb0 ml40 mr12">Reason (if blocked):</p>
                <input type="text" className="f12 fw500 blocked-reason-input" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt32 d-flex-c-f">
          <div className="payment-methods px24 py12">
            <p className="f14 fw700 mb20">Payment methods</p>
            <div>
              <p className="f12">
                Payment categories :
                <span className="text-primary f12 fw600">
                  {paymentCategory}
                </span>
              </p>
            </div>

            <div>
              <p className="f12">
                Mode of payment :
                <span className="text-primary f12 fw600">{modeOfPayment}</span>
              </p>
            </div>
          </div>
          <div className="d-flex">
            <div className="user-credit-scores d-flex-c-f mb32">
              <div className="d-flex-c-f ml48">
                <CreditScoreIcon />
                <div className="ml20">
                  <p className="pb8 m0 f14">Credit Score</p>
                  <p className="m0 f16 fw600">
                    {userDetails?.credit_score || "N/A"}
                  </p>
                </div>
              </div>

              <div className="d-flex-c-f ml48">
                <div style={{ width: "62px", height: "62px" }}>
                  <CircularProgressbarWithChildren
                    strokeWidth={6}
                    styles={buildStyles({
                      pathColor: "#F54B64",
                      rotation: 0.25,
                    })}
                    value={66}
                  >
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.503 11.4842C24.7168 6.2717 20.5976 2.15251 15.3857 1.36572M11.7462 1.36753C5.94782 2.24724 1.50342 7.25338 1.50342 13.2979C1.50342 19.3437 5.95024 24.3516 11.7522 25.2289C12.9557 25.4109 14.1797 25.4113 15.3833 25.2301C20.5976 24.4445 24.7186 20.3229 25.503 15.108"
                        stroke="#4E596F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5708 8.47095C12.2907 8.47095 11.0629 8.9795 10.1577 9.88472C9.25245 10.79 8.7439 12.0177 8.7439 13.2979C8.7439 14.5781 9.25245 15.8058 10.1577 16.711C11.0629 17.6163 12.2907 18.1248 13.5708 18.1248C14.851 18.1248 16.0788 17.6163 16.984 16.711C17.8892 15.8058 18.3978 14.5781 18.3978 13.2979C18.3978 12.0177 17.8892 10.79 16.984 9.88472C16.0788 8.9795 14.851 8.47095 13.5708 8.47095V8.47095Z"
                        stroke="#4E596F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </CircularProgressbarWithChildren>
                </div>

                <div className="ml20">
                  <p className="pb8 m0 f14">Internal Score</p>
                  <p className="m0 f16 fw600">103 / 120</p>
                </div>
              </div>
            </div>
            <div className="user-credit-scores d-flex-c-f">
              <div className="d-flex-c-f ml48">
                <CreditLimitIcon />
                <div className="ml20">
                  <p className="pb8 m0 f14">Credit Limit</p>
                  <p className="m0 f16 fw600">₹{userDetails?.bnpl_wallet}</p>
                </div>
              </div>

              <div className="d-flex-c-f ml48">
                <div style={{ width: "62px", height: "62px" }}>
                  <CircularProgressbarWithChildren
                    strokeWidth={6}
                    styles={buildStyles({
                      pathColor: "#F54B64",
                      rotation: 0.25,
                    })}
                    value={userDetails?.balance?.toFixed(2) || 0}
                  >
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.503 11.4842C24.7168 6.2717 20.5976 2.15251 15.3857 1.36572M11.7462 1.36753C5.94782 2.24724 1.50342 7.25338 1.50342 13.2979C1.50342 19.3437 5.95024 24.3516 11.7522 25.2289C12.9557 25.4109 14.1797 25.4113 15.3833 25.2301C20.5976 24.4445 24.7186 20.3229 25.503 15.108"
                        stroke="#4E596F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.5708 8.47095C12.2907 8.47095 11.0629 8.9795 10.1577 9.88472C9.25245 10.79 8.7439 12.0177 8.7439 13.2979C8.7439 14.5781 9.25245 15.8058 10.1577 16.711C11.0629 17.6163 12.2907 18.1248 13.5708 18.1248C14.851 18.1248 16.0788 17.6163 16.984 16.711C17.8892 15.8058 18.3978 14.5781 18.3978 13.2979C18.3978 12.0177 17.8892 10.79 16.984 9.88472C16.0788 8.9795 14.851 8.47095 13.5708 8.47095V8.47095Z"
                        stroke="#4E596F"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </CircularProgressbarWithChildren>
                </div>

                <div className="ml20">
                  <p className="pb8 m0 f14">Credit Remaining</p>
                  <p className="m0 f16 fw600">
                    ₹{userDetails?.balance || 0} / ₹{userDetails?.bnpl_wallet}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OnboardingStatus />
      </div>
    </div>
  );
};

export default UserDetails;
