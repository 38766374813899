import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import "react-datepicker/dist/react-datepicker.css";
import { CrossIcon } from "../../../../assets/svgIcon";

const CriteriaTextBox = ({ criteria='', score='', isPrimaryText }) => {
  const primaryClass = isPrimaryText ? "text-primary" : "";
  return (
    <div style={{minHeight:'20px'}} className="d-flex-c-s pb16">
      <p style={{maxWidth:'80%'}} className={`${primaryClass} fw500 f12 m0 text-ellipsis`}>{criteria}</p>
      <p className={`${primaryClass} fw600 f12 m0`}>{score}</p>
    </div>
  );
};

const Filters = ({isScoringCriteriaBoxOpen, setScoringCriteriaBox}) => {
  return (
    <Modal maxWidth="xl" open={isScoringCriteriaBoxOpen}>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div>Scoring Criteria </div>
        <CrossIcon
          onClick={() => {
            setScoringCriteriaBox(false)
          }}
          className="cross-icon"
        />
      </div>

      <div className="scoring-criteria">
        <div className="box">
          <div className="p16 column">
              <CriteriaTextBox criteria={`Age`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`COMPANY`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`SALARY`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`RESIDENCE`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`YEARS IN RESIDENCE`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`UID ADDRESS`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`CIBIL`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`DEROGATORY IN CIBIL`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`DPD`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`ACTIVE LOAN`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`BSA`} score={`10`} isPrimaryText  />
              <CriteriaTextBox criteria={`BSA AVG BALANCE`} score={`10`} isPrimaryText  />
          </div>
          <div className="p16 column"> 
          <CriteriaTextBox criteria={`20-25yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Non CAT`} score={`10`}  />
              <CriteriaTextBox criteria={`15-20K`} score={`10`}  />
              <CriteriaTextBox criteria={`Rented`} score={`10`}  />
              <CriteriaTextBox criteria={`0-5yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Not same as residence`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 600`} score={`10`}  />
              <CriteriaTextBox criteria={`Yes`} score={`10`}  />
              <CriteriaTextBox criteria={`60+`} score={`10`}  />
              <CriteriaTextBox criteria={`5-10`} score={`10`}  />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>
          <div className="p16 column"> 
          <CriteriaTextBox criteria={`25-30yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Non CAT`} score={`10`}  />
              <CriteriaTextBox criteria={`15-20K`} score={`10`}  />
              <CriteriaTextBox criteria={`Rented`} score={`10`}  />
              <CriteriaTextBox criteria={`0-5yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Not same as residence`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 600`} score={`10`}  />
              <CriteriaTextBox criteria={`Yes`} score={`10`}  />
              <CriteriaTextBox criteria={`60+`} score={`10`}  />
              <CriteriaTextBox criteria={`5-10`} score={`10`}  />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>
          <div className="p16 column"> 
          <CriteriaTextBox criteria={`30-40yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Non CAT`} score={`10`}  />
              <CriteriaTextBox criteria={`15-20K`} score={`10`}  />
              <CriteriaTextBox criteria={`Rented`} score={`10`}  />
              <CriteriaTextBox criteria={`0-5yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Not same as residence`} score={`10`}  />
              <CriteriaTextBox  />
              <CriteriaTextBox criteria={`Yes`} score={`10`}  />
              <CriteriaTextBox criteria={`60+`} score={`10`}  />
              <CriteriaTextBox  />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>
          <div className="p16 column"> 
          <CriteriaTextBox criteria={`40-50yrs`} score={`10`}  />
              <CriteriaTextBox   />
              <CriteriaTextBox criteria={`15-20K`} score={`10`}  />
              <CriteriaTextBox  />
              <CriteriaTextBox criteria={`0-5yrs`} score={`10`}  />
              <CriteriaTextBox  />
              <CriteriaTextBox criteria={`Below 600`} score={`10`}  />
              <CriteriaTextBox />
              <CriteriaTextBox  />
              <CriteriaTextBox   />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>
          <div className="p16 column"> 
          <CriteriaTextBox criteria={`50-55yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Non CAT`} score={`10`}  />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>
          <div className="p16 "> 
          <CriteriaTextBox criteria={`Above 55yrs`} score={`10`}  />
              <CriteriaTextBox criteria={`Non CAT`} score={`10`}  />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox />
              <CriteriaTextBox criteria={`Chq/Nach Bounce > 3`} score={`10`}  />
              <CriteriaTextBox criteria={`Below 5000`} score={`10`}  />
          </div>

        </div>
        <div className="p12 total-score"> 
              <div style={{minHeight:'20px'}} className="d-flex-c-s">
              <p className="fw500 f12 m0 text-ellipsis">{`TOTAL SCORE`}</p>
             <p className={`fw600 f12 m0`}>{`120`}</p>
            </div>
          </div>
        <div className="d-flex-c-s manual-verify px16">
        <div className="d-flex-c-s">
              <p className="fw500 f12 m0 text-primary pr16">{`Anybody below  |  80`}</p>
             <p className={`fw600 f12 m0`}>{`No loan`}</p>
            </div>
            <div className="d-flex-c-s">
              <p className="fw500 f12 m0 text-primary pr16">{`Anybody between  |  80-90`}</p>
             <p className={`fw600 f12 m0`}>{`Manual verification`}</p>
            </div>
            <div className="d-flex-c-s">
              <p className="fw500 f12 m0 text-primary pr16">{`Anybody above  |  90`}</p>
             <p className={`fw600 f12 m0`}>{`Loan approved`}</p>
            </div>
            </div>
      </div>
    </Modal>
  );
};

export default Filters;
