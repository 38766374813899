import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { CrossIcon, DiscardIcon, SaveBtnIcon } from "../../../assets/svgIcon";


const criteriaInitialState = [
  {
   name:'Non Cat',
   value: '6'
  },
  {
    name:'Other D/E/F',
    value: '5'
  },
  {
    name:'CAT A/B/C',
    value: '8'
  },
]

const EditCriteria = ({ isEditCriteriaModalOpen, setCriteriaModal }) => {
  const [scoringFields, setScoringFields] = useState(criteriaInitialState)

  const handleScoringFieldChange = (event, index) => {
    let data = [...scoringFields];
    data[index][event.target.name] = event.target.value;
    setScoringFields(data);
  }

  const onCriteriSubmit = () => {
    console.log('scoringFields', scoringFields)
  }

  return (
    <Modal maxWidth="sm" open={isEditCriteriaModalOpen}>
      <div className="edit-criteria-modal">
        <div className="f16 d-flex-c-c fw600 mb24">
          <div className="fw600">Edit criteria </div>
          <CrossIcon
            onClick={() => {
              setCriteriaModal(false);
            }}
            className="cross-icon"
          />
        </div>

        <div className="edit-criteria-box">
          <div className="disabled-input-ui text-primary">Company</div>
          <input className="edit-input" value={10} />
        </div>
        <div className="fcA7A9BE my24 f12">Conditions</div>

        {scoringFields.map((item, index) => {
          return (
            <div className="edit-criteria-box mb10">
            <div className="disabled-input-ui fw400 f14">{item.name}</div>
            <input name='value' onChange={event => handleScoringFieldChange(event, index)} className="edit-input" value={item.value} />
          </div>
          )
        })}

        <div className="d-flex-c-c mt56">
          <button className="d-flex-c-c ylw-secondary-btn mr12 fw500 discard-btn">
            <DiscardIcon className="mr8" /> Discard
          </button>
          <button onClick={onCriteriSubmit} className="d-flex-c-c ylw-primary-btn px24 py10 fw500">
            <SaveBtnIcon className="mr8" /> Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditCriteria;
