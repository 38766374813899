import React, { useState } from "react";
import "./creditBureau.scss";
import CustomTabs from "../../../../components/CustomTabs";
import CreditDetails from "./CreditDetails";
import LoanAccount from "./LoanAccount";
import DpdDetails from "./DpdDetails";
import BanksList from "./BanksList";
import BankDetails from "./BankDetails";

const bankListArr = ['ICICI Bank', 'Axis Bank', 'Kotak Mahindra Bank', 'Punjab National Bank', 'SBI Bank', 'Axis Bank - CP', 'Kotak Mahindra Bank - CP', 'Punjab National Bank - CP', 'SBI Bank - CP', 'ICICI Bank 4', 'ICICI Bank 2', 'ICICI Bank 3',]


const CreditBureau = () => {
  const [activeBank, setActiveBank] = useState(bankListArr[0])
  
  const handleNavClick = (bank) => {
      setActiveBank(bank)
  }

  return (
    <div className="creditBureau-wrap">
      <DpdDetails />
      <div className="bank-wrap">
        <BanksList activeBank={activeBank} bankListArr={bankListArr} handleNavClick={handleNavClick} />
        <BankDetails activeBank={activeBank} />
      </div>
    </div>
  );
};

export default CreditBureau;
