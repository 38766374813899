import { GET, POST,PUT } from '../http';
import { userApiUrls } from '../Urls';

export const fetchUserList = (skip, limit) => {
    return GET({url: `${userApiUrls.listPath}?page=${skip}&data_per_page=${limit}`});
  };

export const getUserDetails = (uuid) => {
  return GET({url: `${userApiUrls.detailsPath}?uuid=${uuid}`});
}  

export const updateProfileStatus = (data) => {
  return POST({url: `${userApiUrls.updateProfileStatusPath}`, data});
}

export const fileUpload = (formData) => {
  return POST({url: `${userApiUrls.uploadPath}`, data:formData})
}

export const updateProfileData = (data) => {
  return PUT({url: `${userApiUrls.profilePath}`, data})
}