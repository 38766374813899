import React from "react";
import { Avatar } from "@material-ui/core";

const getRandomColor = (name) => {
  if (!name) return "#E8E8EB";

  let hex = Math.floor(Math.random() * 0xffffff);
  let color = "#" + hex.toString(16);

  return color;
};

const useColumns = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: () => <span className="header-title pl24">USER ID</span>,
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <>
            <div className="tb-rank text-ellipsis tb-text">#{value}</div>
          </>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Name</span>,
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <div className="tb-name">
            <Avatar
              style={{
                backgroundColor: getRandomColor(value),
              }}
            >
              {value?.split("")[0]}
            </Avatar>
            <p className="text-ellipsis">{value}</p>
          </div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">client ID</span>,
        accessor: "clientID",
        Cell: ({ cell: { value } }) => (
          <div className="tb-clientId text-ellipsis tb-text">{value}</div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Phone No.</span>,
        accessor: "phoneNo",
        Cell: ({ cell: { value } }) => (
          <div className="tb-phoneNo text-ellipsis">{value}</div>
        ),
        isOccurrence: true,
      },
      {
        Header: () => <span className="header-title pl24">Email Id</span>,
        accessor: "emailId",
        Cell: ({ cell: { value } }) => (
          <div className="tb-emailId text-ellipsis">{value}</div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Credit Score</span>,
        accessor: "creditScore",
        Cell: ({ cell: { value } }) => (
          <div className="tb-creditScore text-ellipsis tb-text">{value}</div>
        ),
      },
      {
        Header: () => (
          <span className="header-title pl24">Activation Date</span>
        ),
        accessor: "activationDate",
        Cell: ({ cell: { value } }) => (
          <div className="tb-activationDate text-ellipsis">{value}</div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Internal Score</span>,
        accessor: "internalScore",
        Cell: ({ cell: { value } }) => (
          <div className="tb-internalScore text-ellipsis">{value}</div>
        ),
      },
      {
        Header: () => <span className="header-title pl24">Status</span>,
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <div className="tb-status text-ellipsis tb-text">{value}</div>
        ),
      },
    ],
    []
  );
  return { columns };
};

export default useColumns;
