import React from "react";
import { Tooltip } from "@material-ui/core";

const CustomTooltip = (props) => {
  const { title, children, multiline, placement = "top", ...restProps } = props; 

  if (!title) {
    return <>{children}</>
  }

  let classes = {};
  if (multiline) {
    classes = {
      tooltip: "ylw_tooltip",
    };
  }
  return (
    <Tooltip
      title={title}
      classes={classes}
      placement={placement}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip