import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { noop } from '../../utils';

const NavSpan = ({ children, to, isActive=false, exact, className }) => {
  const linkProps = {
    to,
  };
  if (exact) {
    linkProps.exact = true;
  } else {
    linkProps.isActive = isActive;
  }

  return (
    <NavLink {...linkProps}>
      <span className={`${className} nav-span-content`}> {children} </span>
    </NavLink>
  );
};

NavSpan.defaultProps = {
  children: null,
  to: '',
  isActive: noop,
  exact: false,
};

NavSpan.propTypes = {
  to: PropTypes.string.isRequired,
  isActive: PropTypes.func.isRequired,
  exact: PropTypes.bool.isRequired,
};
export default NavSpan;
