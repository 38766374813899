import React from "react";
import {
  HorizontalIcon,
  SuccessDotIcon,
  AddressOutlineIcon,
} from "../../../../assets/svgIcon";
import AssertDetails from "./AssertDetails";

const BankDetails = ({ activeBank }) => {
  return (
    <div className="bank-details p16">
      <div className="d-flex-c-f">
        <p className="f16 fw700">{activeBank}</p>
        <HorizontalIcon className="mx20" />
        <p className="f12 fw500">Active loan</p>
        <SuccessDotIcon className="mx12" />
        <p className="f12 fw700">Yes</p>
        <HorizontalIcon className="mx20" />
        <p className="f12 fw500 mr8">Loan Tyoe : </p>
        <p className="f14 fw700 mr16">Retail</p>
      </div>
      <div className="bank-details-grid">
        <div>
          <AssertDetails
            title={`Loan date`}
            information={`22 July 2022`}
            className="grid-row-1-1 pt16"
          />
          <AssertDetails
            title={`Duration`}
            information={`6 months`}
            className="grid-row-1-1 pt4"
          />
          <AssertDetails
            title={`EMI`}
            information={`₹3000 per month`}
            className="grid-row-1-1 pt4"
          />
        </div>
        <div>
          <AssertDetails
            title={`Sanction amount`}
            information={`₹18,000`}
            className="grid-row-1-1 pt16"
          />
          <AssertDetails
            title={`Due amount`}
            information={`₹15,000`}
            className="grid-row-1-1 pt4"
          />
          <AssertDetails
            title={`Next EMI date`}
            information={`22 August 2022`}
            className="grid-row-1-1 pt4"
          />
        </div>
        <div></div>
      </div>

      <div className="dpd-bar d-flex-c-s px20">
        <div className="f12 fc4E596F fw500">
          DPD 0+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 1+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 30+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 60+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 90+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 100+ <span className="ml16 fw600">2</span>
        </div>
      </div>

      <div className="address-and-contact mt20">
        <div className="f14 fw500">Address and contact</div>
        <div className="mt12 address-container-grid">
          <div className="d-flex-c-f">
            <AddressOutlineIcon className="mr10" />
            <div className="f12 fw500">
              S/O Dnyaneshvar Pavade Maldhamani PO Hingoli Hingoli <br/> MAHARASHTRA
              453212 Pharbhani MAHARASHTRA453212, MH
            </div>
          </div>
          <div className="f12"><SuccessDotIcon className="ml48 mr12" /> Verified</div>
        </div>

        <div className="mt12 address-container-grid">
          <div className="d-flex-c-f">
            <AddressOutlineIcon className="mr10" />
            <div className="f12 fw500">
            9685745123
            </div>
          </div>
          <div className="f12 d-flex-c-f"><SuccessDotIcon className="ml48 mr12" /> Verified</div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
