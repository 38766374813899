import React, { useState } from "react";
import { Form } from "react-final-form";
import { FormInput } from "../../components/Form";
import { Validation } from "../../components/Form";
import {loginUser} from '../../adapters/auth'
import { useSnackbar } from 'notistack';
import {setUserDetails, setAuthToken} from '../../utils/user'
import { useHistory } from "react-router";
import {setLoggedInUserData} from '../../store/actions/authActions'
import { useDispatch } from "react-redux";



const LoginForm = ({ submitting, invalid, handleSubmit, isSubmitting }) => {
  return (
      <form onSubmit={handleSubmit}>
        <div>
          <FormInput
            validate={Validation.required()}
            className="w-full"
            autoComplete="off"
            size="lg"
            required
            placeholder="Phone Number"
            name="phone_number"
            labelText="Phone Number"
            type="number"
          />
          <FormInput
            validate={Validation.required()}
            className="w-full"
            autoComplete="off"
            size="lg"
            required
            placeholder="Password"
            name="password"
            labelText="Password"
            type="password"
          />
        </div>
        <button
          id="submitbtn"
          disabled={submitting || invalid || isSubmitting}
          onClick={handleSubmit}
          type="submit"
          className="btn-primary-4"
        >
          {isSubmitting ? (
            <p>Please Wait... </p>
          ) : (
            <p> Login </p>
          )}
        </button>
      </form>
  );
};

const AdHocModal = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory()
  const dispatch = useDispatch()



  const handleSubmit = (values) => {
    setSubmitting(true)
    loginUser(values).then(res => {
         const {status, message, payload } = res || {}
         
         if (!status) {
          enqueueSnackbar(message, {variant: 'error'});
          return;
         }        
         dispatch(setLoggedInUserData(payload.user))
         setUserDetails(payload.user)
         setAuthToken(payload.token)
         enqueueSnackbar('you are successfully logged in', {variant: 'success'});
         
         // Time to let the user read success message
         setTimeout(() => {
          history.push('/users')
          closeSnackbar()
         }, 1000)

    }).catch(error => {
      console.log('error', error)
    }).finally(() => {
      setSubmitting(false)
    })
  };
  return (
      <>
        <Form
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        keepDirtyOnReinitialize
        component={LoginForm}
        />
      </>
  );
};

export default AdHocModal;
