import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';
import {LogoDashboard} from '../../assets/svgIcon'


const Header = () => {
  return (
    <AppBar color="inherit" position="fixed" className="main-header">
      <Toolbar className="header-toolbar">
           <LogoDashboard />
      </Toolbar>
    </AppBar>
  );
};

export default Header;