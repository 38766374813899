import React from "react";
import Modal from "../../../../components/Modal";
import { CrossIcon } from "../../../../assets/svgIcon";
import AssertDetails from "./AssertDetails";

const DpdModal = ({ setDpdModal, isDpdModalOpen }) => {
  return (
    <Modal maxWidth="lg" open={isDpdModalOpen}>
      <div className="f16 d-flex-c-c fw600 mb24">
        <div className="fw600">DPD Details </div>
        <CrossIcon
          onClick={() => {
            setDpdModal(false);
          }}
          className="cross-icon"
        />
      </div>
      <div className="dpd-bar d-flex-c-s px20">
        <div className="f12 fc4E596F fw500">
          DPD 0+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 1+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 30+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 60+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 90+ <span className="ml16 fw600">2</span>
        </div>
        <div className="f12 fc4E596F fw500">
          DPD 100+ <span className="ml16 fw600">2</span>
        </div>
      </div>
      <div className="mx-auto mt24" style={{ maxWidth: "300px" }}>
        <AssertDetails
          title={`Account with max. DPD`}
          information={`Axis Bank`}
          className="grid-row-2-1"
        />
      </div>
    </Modal>
  );
};

export default DpdModal;
